import { TextField } from '@mui/material';
import { theme } from '../setting';

/**
 * @param {string} label - Label
 * @param {string} filterValue - Current filter value
 * @param {function} setFilterValue - Update filter value
 *
 * @return {JSX.element} Filter input
 */
function FilterInput({ label, filterValue, setFilterValue }) {
  const onChange = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <TextField
      sx={{
        '& .MuiOutlinedInput-root.Mui-focused': {
          '& > fieldset': {
            borderColor: theme.palette.orange.main,
          },
        },
      }}
      label={label}
      value={filterValue}
      onChange={onChange}
    />
  );
}

export default FilterInput;
