import { theme } from '../../setting';
import { lighten } from '@mui/material/styles';
import { Button as MUIButton } from '@mui/material';

/**
 * Button component
 *
 * @param {function} onClickHandler // OnClick function
 * @param {function} children // if childeren elements are available they will be shown
 *
 * @return {JSX.Element}
 */
export default function Button({ onClickHandler, children }) {
  return (
    <MUIButton
      onClick={onClickHandler}
      sx={{
        padding: '8px 16px',
        background: theme.palette.primary.main,
        color: theme.palette.white,
        margin: '0 auto',
        display: 'flex',
        '&:hover': {
          background: lighten(theme.palette.primary.main, 0.3),
        },
        '&:focus': {
          background: lighten(theme.palette.primary.main, 0.3),
        },
      }}
    >
      {children}
    </MUIButton>
  );
}
