import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InfoIcon from '@mui/icons-material/Info';

export default function MobileNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: 3,
        display: { xs: 'block', md: 'none' },
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          component={Link}
          to='/'
          label='Poetsopdrachten'
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to='/factureren'
          label='Te factureren'
          icon={<AccountBalanceWalletIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to='/info'
          label='Info'
          icon={<InfoIcon />}
        />
      </BottomNavigation>
    </Box>
  );
}
