import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import FilterDateRange from './FilterDateRange';
import FilterInput from './FilterInput';
import FilterSelect from './FilterSelect';
import SearchIcon from '@mui/icons-material/Search';
import { gridFilterTitle } from '../assets/styling/Grid';
import { searchIconStyling } from '../assets/styling/IconStyling';
import { filterContainerComponent } from '../assets/styling/FilterComponent';

export default function DealerFilter({ onFilter, conditionOptions }) {
  const [chassisFilter, setChassisFilter] = useState('');
  const [cleaningCompanyFilter, setCleaningCompanyFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState([null, null]);

  const onHandleClick = (e) => {
    e.preventDefault();
    onFilter(chassisFilter, cleaningCompanyFilter, statusFilter, dateRangeFilter);
  };

  return (
    <div style={filterContainerComponent}>
      <Grid container>
        <Grid item xs={12} md>
          <Grid sx={gridFilterTitle}>Chassisnummer</Grid>
          <FilterInput
            label='Chassisnummer&hellip;'
            filterValue={chassisFilter}
            setFilterValue={setChassisFilter}
          />
        </Grid>
        <Grid item xs={12} md>
          <Grid sx={gridFilterTitle}>Poetsbedrijf</Grid>
          <FilterInput
            label='Poetsbedrijf&hellip;'
            filterValue={cleaningCompanyFilter}
            setFilterValue={setCleaningCompanyFilter}
          />
        </Grid>
        { conditionOptions && conditionOptions.length > 0 &&
          <Grid item xs={12} md>
            <Grid sx={[gridFilterTitle, { minWidth: '110px' }]}>Status</Grid>
            <FilterSelect
              name='status'
              label='Status&hellip;'
              options={conditionOptions}
              filterValue={statusFilter}
              setFilterValue={setStatusFilter}
            />
          </Grid>
        }
        <Grid item xs={12} md className='date-picker'>
          <Grid sx={gridFilterTitle}>Datum</Grid>
          <div>
            <FilterDateRange
              filterValue={dateRangeFilter}
              setFilterValue={setDateRangeFilter}
            />
          </div>
        </Grid>
        <Grid>
          <Button sx={searchIconStyling} startIcon={<SearchIcon />} onClick={onHandleClick}>Zoek</Button>
        </Grid>
      </Grid>
    </div>
  );
}
