import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { defaultModalStyle } from '../assets/styling/modal';
import Typography from '@mui/material/Typography';
import { CloseIcon } from '../assets/icon/close';

export default function DefaultModal({ onClose, open, children }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
    >
      <Box sx={[defaultModalStyle, { maxWidth: { xs: 275, md: 720 } }]}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{ fontWeight: 'bold' }}
          >
            Prijsopgave
          </Typography>
          <Typography
            sx={{ cursor: 'pointer' }}
            id="modal-modal-close"
            variant="h4"
            component="h2"
            onClick={onClose}
          >
            <CloseIcon/>
          </Typography>
        </div>
        { children }
      </Box>
    </Modal>
  );
}
