import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { theme } from '../setting';
import { useEffect, useState } from 'react';

/**
 * @param {string} filterValue - Current filter value
 * @param {function} setFilterValue - Update filter value
 *
 * @return {JSX.element} Filter input
 */
function FilterDateRange({ setFilterValue }) {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    const incorrectRange = new Date(fromDate).getTime() / 1000 > new Date(toDate).getTime() / 1000;
    setWarning(incorrectRange);
    setFilterValue([fromDate, toDate]);
  }, [setFilterValue, fromDate, toDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        onChange={() => {}}
        renderInput={() => (
          <>
            <TextField
              onChange={(e) => setFromDate(e.target.value)}
              value={fromDate}
              type='date'
              id='fromDate'
              label='Van'
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': {
                    borderColor: `${theme.palette.orange.main}`,
                  },
                },
                width: '140px',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              onChange={(e) => setToDate(e.target.value)}
              value={toDate}
              type='date'
              id='toDate'
              label='Tot'
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': {
                    borderColor: `${theme.palette.orange.main}`,
                  },
                },
                width: '140px',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        )}
       />
      {warning && <p>Einddatum kan niet eerder zijn dan startdatum</p>}
    </LocalizationProvider>
  );
}

export default FilterDateRange;
