import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FormControlLabel } from '@material-ui/core';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import { theme } from '../setting';
import { gridStyle } from '../assets/styling/Grid';
import DownloadInvoice from './DownloadInvoice';
import { useCallback } from 'react';

const columns = [
  {
    field: 'vin',
    headerName: 'Chassisnummer',
    minWidth: 170,
    flex: 1,
    sortable: false,
  },
  {
    field: 'orderstatus',
    headerName: 'Ordersoort',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'brand',
    headerName: 'Merk',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'model',
    headerName: 'Model',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'kenteken',
    headerName: 'Kenteken',
    minWidth: 125,
    flex: 1,
    sortable: false,
  },
  {
    field: 'repolishing',
    headerName: 'Napoetsen',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'commentaar',
    headerName: 'Info',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'aflevering',
    headerName: 'Aflevering',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'price',
    headerName: 'Prijsopgave',
    minWidth: 100,
    flex: 1,
    sortable: false,
    cellClassName: (params) =>
      params.row.approved ? 'approved' : 'not-approved',
  },
  {
    field: 'gefactureerd',
    headerName: 'Gefactureerd',
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      if (params.row.invoiced) {
        return (
          <DownloadInvoice invoiceId={params.row.invoice.id} />
        );
      }

      return 'Niet gefactureerd';
    },
  },

  // Column with icons for editing
  {
    field: 'acties',
    headerName: 'Acties',
    cellClassName: 'action-info action-approve',
    renderCell: (params) => {
      const quote = params.row;
      const handleClick = () => {
        quote.onPriceClick(quote);
      };
      if (quote.price) {
        return null;
      }

      return (
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ cursor: 'pointer' }}
        >
          <FormControlLabel
            control={
              <EuroSymbolIcon
                style={{ color: theme.palette.black }}
                onClick={handleClick}
              />
            }
           label=''/>
        </div>
      );
    },
  },
];

export default function CarList({ items, onPriceClick, selectionModel, onSelectionModelChange }) {
  const onPriceClickInner = useCallback((quote) => onPriceClick(quote), [onPriceClick]);
  const isRowSelectable = useCallback((params) => !params.row.invoiced, []);
  const onSelectionModelChangeInner = useCallback((ids) => onSelectionModelChange(ids), [onSelectionModelChange]);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <div className='list-container'>
        <div style={{ display: 'flex', height: '100%', marginBottom: '60px' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              disableColumnMenu
              sx={gridStyle}
              autoHeight
              checkboxSelection
              disableSelectionOnClick
              isRowSelectable={isRowSelectable}
              selectionModel={selectionModel}
              onSelectionModelChange={onSelectionModelChangeInner}
              rows={items.map((quote) => {
                const kenteken = quote.licenseplate ? `+${quote.licenseplate}` : '';

                return {
                  ...quote,
                  kenteken,
                  price: quote.quote ? `€ ${quote.quote}` : '',
                  vin: quote.chassisnumber ? quote.chassisnumber : 'Niet Bekend',
                  aflevering: quote.deliveryAt ? (new Date(quote.deliveryAt)).toLocaleDateString('nl-NL') : ' - ',
                  commentaar: quote.comments ? quote.comments : ' - ',
                  orderstatus: quote.ordertype ? quote.ordertype : 'Niet Bekend',
                  repolishing: quote.repolish ? quote.repolish : ' - ',
                  seller: quote.salesperson ? quote.salesperson : ' - ',
                  onPriceClick: onPriceClickInner,
                };
              })}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </>
  );
}
