import { useQuery } from 'react-query';
import { defaultPostHeaders, getDataFromApi, pushDataToApi } from '../dao/api';

export function useGetSettings() {
  const url = '/settings';

  return useQuery({
    queryKey: url,
    queryFn: () => getDataFromApi(url),
  });
}

export async function patchSettings(defaultPriceNewCar, defaultPriceUsedCar) {
  return pushDataToApi(
    '/settings',
    defaultPostHeaders,
    JSON.stringify({
      defaultPriceNewCar,
      defaultPriceUsedCar,
    }),
    'PATCH'
  );
}
