import {
  buttonStyling,
  gradientButtonStyling,
} from '../assets/styling/buttons';
import Typography from '@mui/material/Typography';
import { BroomIcon } from '../assets/icon/broom';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import TextField from '@mui/material/TextField';
import { Alert, Button, CircularProgress } from '@mui/material';
import DefaultModal from '../components/DefaultModal';
import { useSettings } from '../contexts/SettingsProvider';

export default function QuoteModal ({ quote, closeModal, saveQuote, mutationError, mutationStatus }) {
  const settings = useSettings();
  let quoteComment = '';
  let quoteAmount = '';
  let defaultPrice = '';

  if (quote) {
    quoteComment = quote.comments;
    quoteAmount = quote.quote;
    defaultPrice = quote.ordertype === 'Gebruikt'
      ? settings.defaultPriceUsedCar
      : settings.defaultPriceNewCar;
    // Set default from settings when no amount has been set yet.
    if (!quoteAmount) {
      quoteAmount = defaultPrice;
    }
  }

  function handleChangeComments (e) {
    quoteComment = e.target.value;
  }

  function handleChangeQuote (e) {
    quoteAmount = Number(e.target.value.replace(',', '.'));
  }

  return (
    <DefaultModal
      open={quote !== null}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
    >
      <Typography id="modal-modal-description" sx={{ pt: 4, pb: 3 }}>
        Je staat op het punt om een prijsopgaaf in te dienen. Prijzen boven de { defaultPrice } euro moeten eerst worden goedgekeurd door de dealer.
      </Typography>
      <div className="modalPrice">
        <p>Prijs: €</p>
        <TextField
          className="modal-modal-price"
          sx={{ ml: 1, width: '10vw' }}
          id="outlined-basic"
          onChange={handleChangeQuote}
          defaultValue={quoteAmount}
          variant="outlined"
        />
      </div>
      <Typography id="modal-modal-remark" sx={{ pb: 1 }}>
        Opmerkingen:
      </Typography>
      <TextareaAutosize
        className="modal-modal-judge-comments"
        onChange={handleChangeComments}
        aria-label="minimum height"
        defaultValue={quoteComment}
        minRows={7}
        style={{ width: '95%' }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingTop: 24,
        }}
      >
        { mutationStatus !== 'loading' &&
          <Button
            onClick={() => saveQuote(quote, quoteAmount, quoteComment)}
            sx={[buttonStyling, gradientButtonStyling]}
          >
            Indienen
            <BroomIcon/>
          </Button>
        }
        { mutationStatus === 'loading' && <CircularProgress /> }

      </div>
      { mutationError && <Alert severity="error">{mutationError.message}</Alert> }
    </DefaultModal>
  );
}
