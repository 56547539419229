import { Grid } from '@mui/material';

function CarCardItem({ title, value, children }) {
  return (
    <>
      <Grid
        item
        xs={5}
        sx={{
          fontWeight: 'bold',
          fontSize: 14,
        }}
      >
        {title}
      </Grid>
      <Grid
        className={title}
        item
        xs={7}
        sx={{
          mb: 0.5,
        }}
      >
        {value}
        {children}
      </Grid>
    </>
  );
}

export default CarCardItem;
