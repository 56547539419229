import { theme } from '../../setting';

export const filterContainerComponent = {
  display: 'flex',
  height: '100%',
  marginBottom: '32px',
  marginTop: 2,
  borderRadius: '10px',
  padding: 24,
  boxShadow: '0 3px 20px 0 #d9d9d9',
  backgroundColor: theme.palette.white,
  position: 'relative',
};
