import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import PrivacyStatement from './views/PrivacyStatement';
import CookieStatement from './views/CookieStatement';
import Info from './views/Info';
import Layout from './components/Layout';
import { theme } from './setting';
import { ThemeProvider } from '@mui/material/styles';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuth, roleDealer, rolePolisher, roleAdmin } from './auth/oktaAuth';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { UserProvider, useUser } from './contexts/UserProvider'
import { SettingsProvider } from './contexts/SettingsProvider';
import Quotations from './views/Quotations';
import User from './views/User';
import ToInvoice from './views/ToInvoice';
import Tasks from './views/Tasks';
import Judge from './views/Judge';
import ProForma from './views/ProForma';
import Settings from './views/Settings';
import './App.css';
import ShowroomQuote from './views/ShowroomQuote';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
    mutations: {
      retry: 3,
    }
  }
});

export default function App() {
  const history = useHistory();

  const restoreOriginalUri = async () => {
    history.replace(toRelativeUrl('', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <SettingsProvider>
              <Layout>
                <Switch>
                  <>
                    <Route path='/login/callback' component={LoginCallback} />
                    <Route path='/privacy-statement' component={PrivacyStatement} />
                    <Route path='/cookie-statement' component={CookieStatement} />
                    <Route path='/info' component={Info} />
                    <SecureRoute path='/admin/acceptance' component={AdminScreen} />
                    <SecureRoute path='/admin/reservations' component={AdminScreen} />
                    <SecureRoute path='/admin/invoice-overview' component={AdminScreen} />
                    <SecureRoute path='/admin/' component={AdminScreen} />
                    <SecureRoute path='/beoordelen' component={Judge} />
                    <SecureRoute path='/proforma' component={ProForma} />
                    <SecureRoute path='/prijsopgaven' component={Quotations} />
                    <SecureRoute path='/poetsopdrachten' component={Tasks} />
                    <SecureRoute path='/factureren' component={ToInvoice} />
                    <SecureRoute path='/gebruiker' component={User} />
                    <SecureRoute path='/instellingen' component={Settings} />
                    <SecureRoute path='/showroom-naloop' component={ShowroomQuote} />
                    <SecureRoute path='/' exact component={HomeRedirect} />
                  </>
                </Switch>
              </Layout>
            </SettingsProvider>
          </QueryClientProvider>
        </UserProvider>
      </ThemeProvider>
    </Security>
  );
}

function HomeRedirect() {
  const { groups } = useUser();
  if (groups.length === 0) {
    return ('...');
  }
  if (groups.includes(rolePolisher)) {
    return <Redirect to='/prijsopgaven' />
  }

  if (groups.includes(roleDealer)) {
    return <Redirect to='/beoordelen' />;
  }

  if (groups.includes(roleAdmin)) {
    return <Redirect to='/instellingen' />;
  }

  return <h2>Error met het laden van de redirect</h2>;
}

function AdminScreen() {
  return (
    <>
      <h2>Admin</h2>
    </>
  );
}
