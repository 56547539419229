import { Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { theme } from './../../setting';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
import Button from './Button';

/**
 * Error message card
 *
 * @param {string} errorMessage // The error message to display
 *
 * @return {JSX.Element}
 */
export default function ErrorCard({ errorMessage }) {
  const history = useHistory();

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          background: theme.palette.white,
          marginTop: 2,
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 2,
          }}
        >
          <ErrorIcon
            sx={{
              marginRight: 2,
              color: theme.palette.red.main,
            }}
          />
          <Typography
            variant='p'
            sx={{
              marginRight: 2,
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
        <Button
          onClickHandler={() => {
            history.go(0);
          }}
        >
          Probeer nogmaals
        </Button>
      </Paper>
    </>
  );
}
