import { useState } from 'react'
import { Alert, Button, Grid, TextField } from '@mui/material'
import { postSyntecUser } from '../../services/userService'
import { gradientButtonStyling } from '../../assets/styling/buttons';

function SyntecForm() {
  const [userName, setUserName] = useState('');
  const [userDirty, setUserDirty] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [saveMsg, setSaveMsg] = useState('');
  const [saveSeverity, setSaveSeverity] = useState('');

  const onChangeUserName = (e) => {
    setUserName(e.target.value);
    setUserDirty(true);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordDirty(true);
  };
  const onSaveSyntec = () => {
    setUserDirty(true);
    setPasswordDirty(true);
    if (userName.length > 0 && password.length > 0) {
      postSyntecUser(userName, password)
        .then(() => {
          setSaveMsg('Gegevens opslagen');
          setSaveSeverity('success');
        })
        .catch((err) => {
          setSaveMsg(`Opslaan mislukt: ${err}`);
          setSaveSeverity('error');
        });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <h2>Syntec login gegevens</h2>
        <TextField
          sx={{ mb: 1 }}
          label="Gebruikersnaam"
          value={userName}
          error={userDirty && userName.length === 0}
          onChange={onChangeUserName}
        />
        <TextField
          label="Wachtwoord"
          value={password}
          error={passwordDirty && password.length === 0}
          onChange={onChangePassword}
        />
        <Button
          sx={[gradientButtonStyling, { mt: 2, p: 1.5 }]}
          variant="contained"
          onClick={onSaveSyntec}
        >
          Opslaan
        </Button>
        {saveMsg && (
          <Alert sx={{ mt: 2 }} severity={saveSeverity}>
            {saveMsg}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

export default SyntecForm;
