import { theme } from '../../setting';

export const searchIconStyling = {
  marginTop: { xs: 0, md: 4 },
  padding: 1,
  background: `linear-gradient(to bottom, #f34b15, #f75c0e 32%, #ff8000)`,
  borderRadius: '5px',
  color: theme.palette.white,
  '&:hover': {
    background: 'linear-gradient(to bottom, #ad4221, #f75c0e 62%, #ff8000)',
  },
};
