import TasksFilter from '../components/TasksFilter';
import { useEffect, useState } from 'react';
import { useGetUserTasks } from '../services/taskService';
import { useUser } from '../contexts/UserProvider';
import ErrorCard from '../components/General/ErrorCard';
import Loading from '../components/General/Loading';
import CarList from '../components/CarList';
import { Button } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { usePostGenerateInvoice } from '../services/invoiceService';
import DownloadDialog, { createFilenameUrl } from '../components/DownloadDialog';

export default function ToInvoice() {
  const { groups } = useUser();
  const [filters, setFilters] = useState({
    polished: true,
  });
  const { isLoading, isError, error, data } = useGetUserTasks(groups, filters);
  const [toInvoice, setToInvoice] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [filename, setFilename] = useState(null);
  const mutation = usePostGenerateInvoice();

  const tasksForCarCard = (tasks) => {
    return tasks.map((task) => ({
      ...task.quotation,
      id: task.id,
      polished: task.polished,
      invoiced: task.invoiced,
      invoicedAt: task.invoicedAt,
      invoice: task.invoice,
      showInvoicedLabel: true,
      showInvoiceButton: true,
    }));
  };

  const onFilter = (chassisnumber, taskStatus, dateRange) => {
    const fr = {
      polished: true,
    }
    if (chassisnumber) {
      fr.chassisnumber = chassisnumber;
    }
    if (taskStatus) {
      fr.invoiced = taskStatus === 'Gefactureerd';
    }
    if (dateRange && dateRange[0]) {
      fr.fromDate = dateRange[0];
    }
    if (dateRange && dateRange[1]) {
      fr.toDate = dateRange[1];
    }
    setFilters(fr);
  };

  const onSelectionModelChange = (ids) => {
    setToInvoice(ids);
  };

  const onClickInvoice = async () => {
    setModalOpen(true);
    await mutation.mutate({ taskIds: toInvoice }, {
      onSuccess: () => {
        setToInvoice([]);
      }
    });
  };

  const onClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (mutation.data) {
      createFilenameUrl(mutation.data, setFileUrl, setFilename)
        .then();
    }
  }, [mutation.data]);

  if (isError) {
    return <ErrorCard errorMessage={error.message} />;
  }

  return (
    <>
      <h1>Te factureren</h1>

      <TasksFilter onFilter={onFilter} statusOptions={['', 'Gefactureerd', 'Niet gefactureerd']} />
      <DownloadDialog filename={filename} href={fileUrl} open={modalOpen} onClose={onClose} mutationStatus={mutation.status} mutationError={mutation.error} />
      { isLoading && <Loading /> }
      { !isLoading && data && data.length === 0 && <h3>Geen opdrachten gevonden</h3> }
      { data && data.length > 0 && toInvoice.length === 0 && <h3>Vink opdrachten aan om te factureren</h3> }
      { toInvoice.length > 0 && <Button sx={{margin:'10px'}} variant='contained' startIcon={<ReceiptIcon />} onClick={onClickInvoice}>Factureer poetsopdrachten</Button> }
      { data && <CarList items={tasksForCarCard(data)} selectionModel={toInvoice} onSelectionModelChange={onSelectionModelChange} /> }

    </>
  );
}
