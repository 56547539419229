import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { useUser } from '../contexts/UserProvider';

export default function Footer() {
  const { user } = useUser();
  if (!user) {
    return null;
  }

  return (
    <Container
      className='footer'
      maxWidth='xl'
      sx={{ display: { xs: 'none', md: 'block' } }}
    >
      <Grid container direction='row' justifyContent='flex-end' sx={{ mb: 4 }}>
        <Link
          style={{ fontSize: '0.875rem', marginRight: '1rem' }}
          to='/privacy-statement'
        >
          Privacy Statement
        </Link>
        <Link style={{ fontSize: '0.875rem' }} to='/cookie-statement'>
          Cookie Statement
        </Link>
      </Grid>
    </Container>
  );
}
