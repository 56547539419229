import Prices from '../components/Admin/Prices';
import { Card, CardContent, Grid } from '@mui/material';
import { useUser } from '../contexts/UserProvider';
import { roleAdmin } from '../auth/oktaAuth';
import { useGetSettings } from '../services/adminService';
import ErrorCard from '../components/General/ErrorCard';
import Loading from '../components/General/Loading';

function Settings() {
  const { user } = useUser();

  const { isLoading, isError, error, data } = useGetSettings();

  if (isError) {
    return <ErrorCard errorMessage={error.message} />;
  }

  if (!user && !user?.groups.includes(roleAdmin)) {
    return '';
  }

  return (
    <>
      <h1>Instellingen</h1>

      {isLoading && <Loading />}
      {data && (
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            sx={{
              p: 2,
              mb: 2,
            }}
          >
            <CardContent>
              <Prices
                defaultPriceUsedCar={data.defaultPriceUsedCar}
                defaultPriceNewCar={data.defaultPriceNewCar}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
}

export default Settings;
