import React from 'react';
import { Icon } from '@material-ui/core';
import Pencil from '../poetstool-pencil.svg';

export const PencilIcon = () => (
  <Icon>
    <img
      className='pencil-icon'
      src={Pencil}
      height={10}
      width={9.136}
      alt='Pencil Icon'
    />
  </Icon>
);
