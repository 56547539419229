import { Card, CardContent, Grid } from '@mui/material';
import { useUser } from '../contexts/UserProvider';
import { roleDealer } from '../auth/oktaAuth';
import { useGetSettings } from '../services/adminService';
import ErrorCard from '../components/General/ErrorCard';
import Loading from '../components/General/Loading';
import ShowroomQuoteForm from '../components/ShowroomQuoteForm';

function ShowroomQuote() {
  const { user } = useUser();

  const { isLoading, isError, error, data } = useGetSettings();

  if (isError) {
    return <ErrorCard errorMessage={error.message} />;
  }

  if (!user && !user?.groups.includes(roleDealer)) {
    return '';
  }

  return (
    <>
      <h1>Showroom naloop</h1>

      {isLoading && <Loading />}
      {data && (
        <Grid item xs={12} sm={6} lg={3}>
          <Card
            sx={{
              p: 2,
              mb: 2,
            }}
          >
            <CardContent>
              <ShowroomQuoteForm />
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
}

export default ShowroomQuote;
