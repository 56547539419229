import ErrorCard from '../components/General/ErrorCard';
import { useState } from 'react';
import Loading from '../components/General/Loading';
import DealerFilter from '../components/DealerFilter';
import { useGetInvoice } from '../services/invoiceService';
import InvoiceList from '../components/InvoiceList';

export default function ProForma() {
  const [filters, setFilters] = useState(null);
  const { isLoading, isError, error, data } = useGetInvoice(filters);

  if (isError) {
    return <ErrorCard errorMessage={error.message} />;
  }

  const onFilter = (chassisnumber, polisher, taskStatus, dateRange) => {
    const fr = {};
    if (chassisnumber) {
      fr.chassisnumber = chassisnumber;
    }
    if (polisher) {
      fr.polisher = polisher;
    }
    if (dateRange && dateRange[0]) {
      fr.fromDate = dateRange[0];
    }
    if (dateRange && dateRange[1]) {
      fr.toDate = dateRange[1];
    }
    setFilters(fr);
  };

  return (
    <>
      <h1>Proforma facturen</h1>

      <DealerFilter onFilter={onFilter} conditionOptions={[]} />
      { isLoading && <Loading /> }
      { !isLoading && data && data.results.length === 0 && <h3>Geen facturen gevonden</h3> }
      { data && <InvoiceList items={data.results} /> }
    </>
  );
}
