import { useState } from 'react';
import ViewDisplaySwitch from '../components/ViewDisplaySwitch';
import CarCard from '../components/Card/CarCard';
import CarList from '../components/CarList';
import ErrorCard from '../components/General/ErrorCard';
import { Redirect } from 'react-router-dom';
import QuoteModal from './QuoteModal';
import { useSaveUserQuotation, useGetUserQuotations } from '../services/quotationService';
import { useUser } from '../contexts/UserProvider';
import { useSettings } from '../contexts/SettingsProvider';
import Loading from '../components/General/Loading';
import CachedIcon from '@mui/icons-material/Cached';
import Button from '@mui/material/Button';
import * as React from 'react';
import { Alert } from '@mui/material';

export default function Quotations() {
  const [viewDisplay, setViewDisplay] = useState('card');
  const [modalItem, setModalItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const { groups } = useUser();
  const { isLoading, isError, error, data, refetch } = useGetUserQuotations(groups);
  const mutation = useSaveUserQuotation();
  const { settings } = useSettings();

  if (isError && error.message.includes('syntec login')) {
    return <Redirect to='/gebruiker' />;
  }

  if (isError) {
    return <ErrorCard errorMessage={error.message} />;
  }

  const rfqForCarCard = (quotations) => {
    return quotations.map((q) => ({
      id: q.id,
      vsb: q.vsb,
      brand: q.brand,
      model: q.modelname,
      licenseplate: q.licenseplate,
      chassisnumber: q.chassisnr,
      ordertype: q.ordertype,
      salesperson: q.salesperson,
      repolish: q.repolish === '1',
      info: q.polish_info,
      deliveryAt: q.date_delivery,
      techready: q.tech_ready === '1',
      establishment: q.establishment,
      hasQuotation: !!q.quotation,
      qid: q.quotation ? q.quotation.id : null,
      approved: q.quotation ? q.quotation.approved : false,
      quote: q.quotation ? q.quotation.quote : null,
      comments: q.quotation ? q.quotation.comments : null,
      judgedAt: q.quotation ? q.quotation.judgedAt : null,
      judgeComments: q.quotation ? q.quotation.judgeComments : null,
      showShowroomLabel: q.quotation ? q.quotation.showroomQuote : false,
      showPolishedButton: false,
      showJudgedLabel: true,
      showQuoteButton: true,
    }));
  };

  const onPriceClick = (item) => {
    setModalItem(item);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalItem(null);
    setModalOpen(false);
  };

  const saveQuote = (rfq, amount, comments) => {
    const dto = {
      hasQuotation: rfq.hasQuotation,
      qid: rfq.qid,
      quote: amount,
      comments: comments,
      rfqId: rfq.id,
      vsb: rfq.vsb,
      licenseplate: rfq.licenseplate,
      chassisnumber: rfq.chassisnumber,
      salesperson: rfq.salesperson,
      ordertype: rfq.ordertype,
      brand: rfq.brand,
      model: rfq.model,
      repolish: rfq.repolish,
      info: rfq.info,
      techready: rfq.techready,
      establishment: rfq.establishment,
      deliveryAt: rfq.deliveryAt,
    };

    mutation.mutate(dto, {
      onSuccess: () => {
        setModalOpen(false);
        setAlertMsg('Prijsopgave ingediend voor keuring');
        setTimeout(() => {
          setAlertMsg('');
        }, 2000);
      },
    });
  };

  const onReload = async () => {
    await refetch();
  }

  return (
    <>
      <h1>Prijsopgaven</h1>

      <ViewDisplaySwitch
        viewDisplay={viewDisplay}
        setViewDisplay={setViewDisplay}
      />

      { alertMsg && <Alert severity="success">{alertMsg}</Alert>}
      <Button onClick={onReload} startIcon={<CachedIcon />}>Ververs</Button>
      { isLoading && <Loading /> }
      { !isLoading && data && data.length === 0 && <h3>Geen prijsopgaven gevonden</h3> }
      { modalOpen && modalItem &&
        <QuoteModal quote={modalItem} closeModal={onCloseModal} saveQuote={saveQuote} mutationError={mutation.error} mutationStatus={mutation.status} settings={settings} /> }
      { viewDisplay === 'list' && data &&
        <CarList items={rfqForCarCard(data)} onPriceClick={onPriceClick} /> }
      { viewDisplay === 'card' && data &&
        <CarCard items={rfqForCarCard(data)} onPriceClick={onPriceClick} /> }
    </>
  );
}
