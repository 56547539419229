import { theme } from '../../setting';

export const gridFilterTitle = {
  fontWeight: 500,
  padding: '0 0 8px',
};

export const gridStyle = {
  border: 'none',
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.table.header,
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '.MuiDataGrid-virtualScroller': {
    backgroundColor: theme.palette.white,
  },
  '.MuiDataGrid-footerContainer': {
    backgroundColor: theme.palette.table.header,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  '.Mui-selected': {},
  '.approved .MuiDataGrid-cellContent': {
    background: theme.palette.success.secondary,
    color: theme.palette.success.main,
    padding: 1,
    borderRadius: 1,
  },
  '.MuiDataGrid-cell[data-field="kenteken"] .MuiDataGrid-cellContent':
    {
      padding: '4px 16px 4px 4px',
      fontFamily: 'kenteken !important',
    },
  '.MuiSvgIcon-root[data-testid="EuroSymbolIcon"]': {
    marginLeft: 2,
  },
  'input.PrivateSwitchBase-input': {
    height: '100% !important',
  }
};
