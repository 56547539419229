import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { gridStyle } from '../assets/styling/Grid';
import DownloadInvoice from './DownloadInvoice';

const columns = [
  {
    field: 'id',
    headerName: 'Factuur ID',
    minWidth: 170,
    flex: 1,
    sortable: false,
  },
  {
    field: 'polisher',
    headerName: 'Poetsbedrijf',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'chassisnumbers',
    headerName: 'Chassisnummers',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'price',
    headerName: 'Totaal',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Aangemaakt op',
    minWidth: 125,
    flex: 1,
    sortable: false,
  },
  {
    field: 'invoiced',
    headerName: 'Factuur',
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <DownloadInvoice invoiceId={params.row.id} />
      )
    },
  },
];

export default function InvoiceList({ items }) {
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <div className='list-container'>
        <div style={{ display: 'flex', height: '100%', marginBottom: '60px' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              disableColumnMenu
              sx={gridStyle}
              autoHeight
              checkboxSelection={false}
              disableSelectionOnClick
              rows={items.map((item) => {
                return {
                  ...item,
                  price: item.amount ? `€ ${String(item.amount).replace('.', ',')}` : '',
                  polisher: item.tasks[0]?.establishment || '',
                  chassisnumbers: item.tasks.map(t => t.quotation.chassisnumber).join('<br>'),
                };
              })}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </>
  );
}
