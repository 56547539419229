import { Container } from '@mui/material';

export default function CookieStatement() {
  return (
    <Container maxWidth='md' className='content' sx={{ mt: 8, mb: 5 }}>
      <h1>Cookie statement</h1>

      <h3>
        Deze verklaring inzake cookies is van toepassing op de website van
        Huiskes-Kokkeler.
      </h3>
      <p>
        U wordt gevraagd bij het openen van de website om akkoord te gaan met de
        optimale website ervaring. Hierbij staan alle cookies ingeschakeld.
        Wanneer u dit niet wenst, kunt u dit via cookie instellingen aanpassen.
        Dit kan van invloed zijn op uw gebruikservaring wanneer u op de website
        bent.
      </p>

      <h3>Wat zijn cookies?</h3>
      <p>
        Cookies zijn bestanden of stukjes informatie die op uw computer kunnen
        worden opgeslagen (of op andere apparaten met internet-toegang, zoals
        een smartphone of tablet) wanneer u de website bezoekt. Een cookie bevat
        gewoonlijk de naam van de website waar de cookie vandaan komt, de
        "levensduur" van de cookie (dat wil zeggen hoe lang de cookie op uw
        computer/toestel zal blijven) en een waarde, die meestal bestaat uit een
        willekeurig gegenereerd uniek nummer.
      </p>

      <h3>Waar gebruiken wij cookies voor?</h3>
      <p>
        Wij gebruiken cookies om de website eenvoudiger in gebruik te maken en
        om de websites en onze producten beter af te stemmen op uw interesses en
        behoeften. Cookies kunnen ook worden gebruikt om de snelheid van uw
        toekomstige activiteiten en sessies op onze websites te verbeteren. Ook
        gebruiken wij cookies om anonieme, geaggregeerde statistieken samen te
        stellen waarmee wij inzicht kunnen krijgen in de manier waarop mensen
        gebruik maken van onze websites, zodat wij de structuur en inhoud van de
        websites kunnen verbeteren. Wij kunnen u op basis van deze informatie
        niet persoonlijk identificeren.{' '}
      </p>
      <p>
        In sommige gevallen koppelen wij cookies van onze websites aan bij ons
        bekende klantgegevens. Dit doen wij om de inhoud van onze communicatie
        nog beter op uw persoonlijke voorkeur af te stemmen. Cookies die dit
        koppelen mogelijk maken, vallen altijd onder onze categorie “optimale
        cookies”. Wanneer u niet wenst dat deze koppeling van gegevens
        plaatsvindt, kunt u voor de categorie “functionele” of “noodzakelijke”
        cookies kiezen.
      </p>

      <h3>Welke soorten cookies gebruikt de website?</h3>
      <p>
        Als bezoeker kunt u kiezen voor drie gradaties van cookiegebruik. Bij de
        keuze voor noodzakelijk cookies ontvangt u een minimum set van cookies,
        waarbij sommige functionaliteiten op de website wegvallen. Bij de keuze
        voor optimaal, de standaard instelling, krijgt u de meest rijke website
        ervaring zoals u altijd gewend was. Er zijn drie categorieën die
        mogelijk gebruikt worden op de website:
      </p>

      <table className='cookie-statement-table'>
        <thead style={{ verticalAlign: 'top', textAlign: 'left' }}>
          <tr>
            <th>Categorie</th>
            <th>Omschrijving</th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: 'top' }}>
          <tr>
            <td>Noodzakelijk</td>
            <td>
              Anonieme cookies die gebruikers in staat stellen de website te
              bekijken en de functies op de website te gebruiken, zoals
              formulieren. Daarnaast worden er anonieme cookies gebruikt om
              inzicht te krijgen in het gebruik en de prestatie van de website.
              Dit zijn zogenaamde analytics cookies.
            </td>
          </tr>
          <tr>
            <td>Functioneel</td>
            <td>
              Cookies waarmee de website de keuzes en voorkeuren van bezoekers
              kan vastleggen. Ook is het mogelijk bepaalde content te delen met
              derden (social functies), feedback te geven op de website en deel
              te nemen aan marktonderzoek (tonen pop-up).
            </td>
          </tr>
          <tr>
            <td>Optimaal</td>
            <td>
              Met deze instelling kiest u voor de beste ervaring op, maar ook
              buiten onze website. Relevante content en/of advertenties worden
              beperkt getoond en voorkeuren worden vastgelegd waardoor we beter
              in staat zijn u een relevant aanbod te doen op onze website(s).
              Ook kunnen in sommige gevallen cookies van onze websites aan bij
              ons bekende klantgegevens gekoppeld worden.{' '}
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Gebruikte cookies</h3>
      <p>
        De onderstaande tabel geeft een overzicht van de verschillende soorten
        cookies die we gebruiken op de website, samen met hun respectievelijke
        doel en duur (dat wil zeggen hoe lang elke cookie op uw toestel/computer
        blijft).
      </p>

      <h4>1. Noodzakelijke cookies</h4>
      <table className='cookie-statement-table'>
        <thead style={{ verticalAlign: 'top', textAlign: 'left' }}>
          <tr>
            <th>Welke partij plaatst het cookie?</th>
            <th>Namen van het cookie</th>
            <th>Omschrijving</th>
            <th>Hoe lang blijft het cookie bewaard?</th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: 'top' }}>
          <tr>
            <td>Google Analytics</td>
            <td>
              _ga
              <br />
              _gid
              <br />
              __utma,
              <br />
              __utmb,
              <br />
              __utmc,
              <br />
              __utmv,
              <br />
              __utmz
              <br />
              __utmx
              <br />
              __utmxx
              <br />
              __USERID
            </td>
            <td>
              Er worden (sessie) cookies geplaatst om een beter inzicht te
              krijgen in het gedrag van de bezoeker aan deze website. Hiermee
              wordt vervolgens de gebruikerservaring op deze website en op
              andere door deze website gebruikte kanalen verbeterd.
            </td>
            <td>Maximaal 2 jaar</td>
          </tr>
          <tr>
            <td>Google Maps</td>
            <td>NID, PREF, SNID</td>
            <td>
              Met behulp van Google Maps wordt op de contactpagina
              gedetailleerde informatie over de locatie van de organisatie
              getoond.
            </td>
            <td>Maximaal 2 jaar</td>
          </tr>
          <tr>
            <td>AddThis</td>
            <td>
              uit
              <br />
              loc
              <br />
              ssh
              <br />
              sshs
              <br />
              di
              <br />
              dt
              <br />
              uid
              <br />
              psc
            </td>
            <td>
              AddThis wordt gebruikt om bezoekers de mogelijkheid te geven om
              pagina’s te delen in sociale netwerken zoals Facebook, Linkedin en
              Twitter.
            </td>
            <td>Maximaal 2 jaar</td>
          </tr>
          <tr>
            <td>AdCalls</td>
            <td>
              acalltracker
              <br />
              acalltrackerreferrer
              <br />
              excludecalltracking
              <br />
              acalltrackersession
              <br />
              acalltrackernumber
            </td>
            <td>
              AdCalls wordt gebruikt om de prestaties (gepleegde
              telefoon-gesprekken) van de verschillende bezoek-kanalen te meten.
            </td>
            <td>maximaal 30 dagen</td>
          </tr>
          <tr>
            <td>Relay42</td>
            <td>_stCookieTest (1st party only)</td>
            <td>
              De _stCookieTest cookie checkt of de browser technisch in staat is
              om cookies op te slaan. De cookie wordt aan het einde van iedere
              sessie automatisch verwijderd. Wanneer de browser cookies
              accepteert, dan zal de cookiewall niet worden geactiveerd en geldt
              de situatie waarin de gebruiker geen consent heeft gegeven voor
              het plaatsen van cookies.
            </td>
            <td>
              _stCookieTest is een sessie cookie, oftewel de cookie wordt
              opgeslagen in de tijdelijke opslag en zal worden verwijderd na het
              beëindigen van de sessie
            </td>
          </tr>
          <tr>
            <td>Relay42</td>
            <td>_svtri (1st party, svtrd.com)</td>
            <td>
              Analyse- & labelbeheer & cookiebarmanager. Dit script wordt
              gebruikt om activiteit op de website te registreren en zo inzicht
              te verkrijgen in de effectiviteit en de kwaliteit ervan. Daarnaast
              beheert dit script ook alle analytische en tracking cookies en
              controleert of aan de toepasselijke regelgeving wordt voldaan.
            </td>
            <td>2 jaar</td>
          </tr>
          <tr>
            <td>Relay42</td>
            <td>_svs (1st party only)</td>
            <td>
              Dit script wordt gebruikt om activiteit op de website te
              registreren en zo inzicht te verkrijgen in de effectiviteit en de
              kwaliteit ervan. Daarnaast beheert dit script ook alle analytische
              en tracking cookies en controleert of aan de toepasselijke
              regelgeving wordt voldaan.
            </td>
            <td>2 jaar</td>
          </tr>
        </tbody>
      </table>

      <h4>2. Functionele cookies</h4>
      <table className='cookie-statement-table'>
        <tbody style={{ verticalAlign: 'top' }}>
          <tr>
            <td>Visual Website Optimizer</td>
            <td>_vis_opt_*</td>
            <td>
              Visual Website Optimizer wordt gebruikt om verschillende pagina’s
              te A/B testen met als doel de site verder te optimaliseren.
            </td>
            <td>Maximaal 100 dagen</td>
          </tr>
          <tr>
            <td>Facebook</td>
            <td>
              c_user, datr,
              <br />
              Fr, Lu, Presence
            </td>
            <td>
              Indien een bezoeker ingelogd is bij Facebook, kan Facebook via de
              website cookies op zijn/haar pc plaatsen die nodig zijn voor het
              liken van berichten en het updaten van zijn/haar status.
            </td>
            <td>Variabel</td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>
              _hjClosedSurveyInvites
              <br />
              _hjDonePolls
              <br />
              _hjMinimizedPolls
              <br />
              _hjDoneTestersWidgets
              <br />
              _hjMinimizedTestersWidgets
              <br />
              _hjIncludedInSample
            </td>
            <td>
              Hotjar wordt gebruikt om feedback te verzamelen d.m.v. enquetes,
              vragenlijsten en polls met als doel de website verder te
              optimaliseren
            </td>
            <td>1 jaar</td>
          </tr>
          <tr>
            <td>Google+</td>
            <td>S, PREF, NID</td>
            <td>
              Deze cookie wordt alleen geplaatst indien u gebruik maakt van
              Google+ en ingelogd bent voor deze service. Door middel van deze
              cookies is het mogelijk om berichten te +1-en.
            </td>
            <td>Variabel</td>
          </tr>
          <tr>
            <td>Twitter</td>
            <td>_twitter_sessguest_id, k, original_referer</td>
            <td>
              Deze cookie wordt alleen geplaatst indien u gebruik maakt van
              Twitter en ingelogd bent voor deze service. Het maakt het mogelijk
              om berichten te (re)tweeten.
            </td>
            <td>Variabel</td>
          </tr>
        </tbody>
      </table>

      <h4>3. Optimale cookies</h4>
      <table className='cookie-statement-table'>
        <tbody style={{ verticalAlign: 'top' }}>
          <tr>
            <td>Google Analytics</td>
            <td>_ga</td>
            <td>
              Er worden (sessie) cookies geplaatst om een beter inzicht te
              krijgen in het gedrag van de bezoeker aan deze website. Hiermee
              wordt vervolgens de gebruikerservaring op deze website en op
              andere door deze website gebruikte kanalen verbeterd.
            </td>
            <td>2 jaar</td>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td>_gid</td>
            <td>
              Er worden (sessie) cookies geplaatst om een beter inzicht te
              krijgen in het gedrag van de bezoeker aan deze website. Hiermee
              wordt vervolgens de gebruikerservaring op deze website en op
              andere door deze website gebruikte kanalen verbeterd.
            </td>
            <td>24 uur</td>
          </tr>
          <tr>
            <td>Google Adwords</td>
            <td>Conversion</td>
            <td>
              Meten van clicks en bestellingen/conversies gedaan via deze
              website om daarmee advertentiecampagnes te optimaliseren.
            </td>
            <td>Maximaal 1 maand</td>
          </tr>
          <tr>
            <td>Doubleclick</td>
            <td>Doubleclick Cookie-ID</td>
            <td>
              Tracking & remarketing. Deze cookie (die via een script wordt
              geplaatst) wordt gebruikt om informatie te verzamelen voor
              advertentiedoeleinden; met name voor het pseudo anoniem volgen van
              schermadvertentie- campagnes en het pseudo anoniem verzamelen van
              gegevens. Deze gegevensverzameling heeft betrekking op de
              land-taalcombinatie in combinatie met het gezochte product, met
              als doel om op externe websites advertenties te tonen met de
              juiste inhoud en in de juiste taal. Dit heeft niet alleen
              betrekking op banners, maar ook op video's.
            </td>
            <td>Maximaal 2 jaar</td>
          </tr>
          <tr>
            <td>ActiveCampaign</td>
            <td>prism_252687669</td>
            <td>
              Tracking om het websitegedrag van bezoekers te verzamelen op de
              website. Door middel van deze gegevens kan de website worden
              verbeterd en relevante content geboden worden aan de bezoeker op
              de website en in mail-campagnes (indien aangemeld).
            </td>
            <td>4 weken</td>
          </tr>
          <tr>
            <td>Facebook</td>
            <td>
              locale, c_user, csm, datr, fr,lu, xs, pk, s, p, act,
              x-src,presence
            </td>
            <td>
              De anonieme gegevens van deze cookie komen samen in een centrale
              adserver. Met de informatie kunnen advertenties gepersonaliseerd
              en gericht getoond worden (bijv. je laatst bekeken product).
            </td>
            <td>Maximaal 1 jaar</td>
          </tr>
          <tr>
            <td>Chat</td>
            <td>
              ssupp.vid,
              <br />
              ssupp.animbnr,
              <br />
              ssupp.chatid
            </td>
            <td>
              De cookies onthouden welke pagina’s zijn bekeken en welke acties
              zijn uitgevoerd om zodoende de bezoeker die een Chatgesprek aan
              gaat optimaal te kunnen helpen. Wannneer u uw naam invult in het
              venster wordt deze onthouden om u in de toekomst te herkennen.
              Tevens kunnen ip-adres, browser, locatie en besturingssysteem
              worden opgeslagen.
            </td>
            <td>Maximaal 1 jaar</td>
          </tr>
          <tr>
            <td>DPG Media</td>
            <td></td>
            <td>
              Cookies om bezoeken aan de website te herkennen met als doel het
              retargeten van deze bezoekers op DPG Media websites.
            </td>
            <td>30 dagen</td>
          </tr>
        </tbody>
      </table>

      <p>
        Dit cookie statement is op <strong>9 december 2021</strong>{' '}
        gepubliceerd. We werken continu aan het aanvullen en verbeteren hiervan.
        We raden daarom aan om dit statement regelmatig te bekijken om op de
        hoogte te blijven van wijzigingen.
      </p>
    </Container>
  );
}
