import { defaultPostHeaders, getDataFromApi, pushDataToApi } from '../dao/api';

export async function getPolishersWithSyntecDetails() {
  return getDataFromApi(`/users/polishers`);
}

export async function getUser(id) {
  return getDataFromApi(`/users/${id}`);
}

export async function postSyntecUser(username, password) {
  return pushDataToApi('/users/syntec', defaultPostHeaders, JSON.stringify({
    username,
    password,
  }));
}
