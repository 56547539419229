import { useState } from 'react';
import { Alert, Button, Grid, TextField } from '@mui/material';
import { patchSettings } from '../../services/adminService';
import { gradientButtonStyling } from '../../assets/styling/buttons';

function Prices({ defaultPriceUsedCar, defaultPriceNewCar }) {
  const [priceUsed, setPriceUsed] = useState(defaultPriceUsedCar);
  const [priceNew, setPriceNew] = useState(defaultPriceNewCar);
  const [saveMsg, setSaveMsg] = useState('');
  const [saveSeverity, setSaveSeverity] = useState('');

  const onChangePriceUsed = (e) => {
    setPriceUsed(e.target.valueAsNumber ? e.target.valueAsNumber : null);
  };
  const onChangePriceNew = (e) => {
    setPriceNew(e.target.valueAsNumber ? e.target.valueAsNumber : null);
  };
  const onSave = () => {
    patchSettings(priceNew, priceUsed)
      .then(() => {
        setSaveMsg('Gegevens opslagen');
        setSaveSeverity('success');
      })
      .catch((err) => {
        setSaveMsg(`Opslaan mislukt: ${err}`);
        setSaveSeverity('error');
      });
  };

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <h2>Prijzen</h2>
        <TextField
          sx={{ mb: 1 }}
          label="Gebruikt"
          type="number"
          value={priceUsed}
          onChange={onChangePriceUsed}
        />
        <TextField
          sx={{ mb: 1 }}
          label="Nieuw"
          type="number"
          value={priceNew}
          onChange={onChangePriceNew}
        />
        <Button
          sx={[gradientButtonStyling, { mt: 2, p: 1.5 }]}
          variant="contained"
          onClick={onSave}
        >
          Opslaan
        </Button>
        {saveMsg && (
          <Alert sx={{ mt: 2 }} severity={saveSeverity}>
            {saveMsg}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

export default Prices;
