import { useGetUserQuotations, useJudgeUserQuotation } from '../services/quotationService';
import ErrorCard from '../components/General/ErrorCard';
import { useUser } from '../contexts/UserProvider';
import { useState } from 'react';
import JudgeModal from './JudgeModal';
import CarCard from '../components/Card/CarCard';
import Loading from '../components/General/Loading';
import DealerFilter from '../components/DealerFilter';

export default function Judge() {
  const [modalItem, setModalItem] = useState(null);
  const [filters, setFilters] = useState(null);
  const mutation = useJudgeUserQuotation();
  const { groups } = useUser();
  const { isLoading, isError, error, data } = useGetUserQuotations(groups, filters);

  if (isError) {
    return <ErrorCard errorMessage={error.message} />;
  }

  const quoteToCarCard = (quotations) => {
    return quotations.map((q) => ({
      ...q,
      showShowroomLabel: q.showroomQuote,
      showPolishedButton: false,
      showQuoteButton: false,
      showJudgedLabel: true,
    }));
  };

  const onJudgeClick = (item) => {
    setModalItem(item);
  };

  const onCloseModal = () => {
    setModalItem(null);
  };

  const judgeQuote = (quote, status, comment) => {
    mutation.mutate(
      {
        quote,
        status,
        comment,
      },
      {
        onSuccess: () => {
          setModalItem(null);
        }
      }
    );
  };

  const onFilter = (chassisnumber, polisher, taskStatus, dateRange) => {
    const fr = {};
    if (chassisnumber) {
      fr.chassisnumber = chassisnumber;
    }
    if (polisher) {
      fr.polisher = polisher;
    }
    if (taskStatus) {
      if (taskStatus === 'Akkoord') {
        fr.approved = true;
      }
      if (taskStatus === 'Afgekeurd') {
        fr.approved = false;
      }
    }
    if (dateRange && dateRange[0]) {
      fr.fromDate = dateRange[0];
    }
    if (dateRange && dateRange[1]) {
      fr.toDate = dateRange[1];
    }
    setFilters(fr);
  };

  return (
    <>
      <h1>Prijsopgaven beoordelen</h1>

      <DealerFilter onFilter={onFilter} conditionOptions={['Akkoord', 'Afgekeurd', 'In afwachting']} />
      { isLoading && <Loading /> }
      { !isLoading && data && data.length === 0 && <h3>Geen prijsopgaven gevonden</h3> }
      { modalItem && <JudgeModal quote={modalItem} closeModal={onCloseModal} judgeQuote={judgeQuote} mutationError={mutation.error} mutationStatus={mutation.status} /> }
      { data && <CarCard items={quoteToCarCard(data)} onJudgeClick={onJudgeClick} /> }
    </>
  );
}
