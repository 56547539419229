import React from 'react';
import { Icon } from '@material-ui/core';
import Broom from '../poetstool-broom.svg';

export const BroomIcon = () => (
  <Icon>
    <img
      style={{ marginLeft: 6, marginBottom: -1 }}
      className="broom-icon"
      src={Broom}
      height={14}
      width={13.136}
      alt="Broom Icon"
    />
  </Icon>
);
