import { rolePolisher } from '../auth/oktaAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { defaultPostHeaders, getDataFromApi, pushDataToApi } from '../dao/api';

export function useGetUserTasks(groups, filterParams) {
  let url = '';
  let queryString = '';
  if (filterParams) {
    queryString = '?' + new URLSearchParams(filterParams).toString();
  }
  if (groups.includes(rolePolisher)) {
    url = '/polisher/tasks' + queryString;
  }

  return useQuery({
    queryKey: url,
    queryFn: () => getDataFromApi(url),
    enabled: url !== '',
  });
}

export function usePatchTask() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      const url = `/polisher/tasks/${dto.id}`;
      const requestBody = {
        polished: dto.polished,
      };

      return pushDataToApi(
        url,
        defaultPostHeaders,
        JSON.stringify(requestBody),
        'PATCH',
      );
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: '/polisher/tasks?invoiced=false' });
    }
  });
}
