import DownloadDialog, { createFilenameUrl } from './DownloadDialog';
import * as React from 'react';
import { simpleLink } from '../assets/styling/buttons';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDownloadInvoice } from '../services/invoiceService';

export default function DownloadInvoice({ invoiceId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [filename, setFilename] = useState(null);
  const [mutationStatus, setMutationStatus] = useState(null);
  const [mutationError, setMutationError] = useState(null);
  const [openId, setOpenId] = useState(null);
  const { data: invoiceResponse, isLoading, isError, error } = useDownloadInvoice(openId);
  useEffect(() => {
    if (isLoading) {
      setMutationStatus('loading');
    } else {
      setMutationStatus('');
    }
    if (isError) {
      setMutationError(error);
    } else {
      setMutationError(null);
    }
    if (invoiceResponse) {
      createFilenameUrl(invoiceResponse, setFileUrl, setFilename)
        .then()
        .catch((err)  => setMutationError(err));
    }
  }, [openId, isLoading, isError, error, invoiceResponse]);

  const onClick = (e) => {
    e.preventDefault();
    setModalOpen(true);
    setOpenId(invoiceId);
  };

  const onClose = () => {
    setModalOpen(false);
    setOpenId(null);
  };

  return (
    <>
      <DownloadDialog filename={filename} href={fileUrl} open={modalOpen} onClose={onClose} mutationStatus={mutationStatus} mutationError={mutationError} />
      <Button size='small' sx={simpleLink} onClick={onClick}>Download factuur</Button>
    </>
  )
}
