export default function LabelStatus({ ok, trueLabel, falseLabel }) {
  const color = ok ? '#2e7d32' : '#c62828';
  const bg = ok ? '#e8f5e9' : '#ffebee';
  return (
    <p
      style={{
        fontSize: '10px',
        background: bg,
        color: color,
        padding: '2px 10px ',
        float: 'right',
        margin: '0 2px',
        borderRadius: '3px',
      }}
    >
      {ok ? trueLabel : falseLabel}
    </p>
  );
}
