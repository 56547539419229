import { defaultPostHeaders, getDataFromApi, pushDataToApi } from '../dao/api';
import { roleDealer, rolePolisher } from '../auth/oktaAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';


export function useGetUserQuotations(groups, filterParams) {
  let queryString = '';
  if (filterParams) {
    queryString = '?' + new URLSearchParams(filterParams).toString();
  }
  let url = '';
  if (groups.includes(rolePolisher)) {
    url = '/polisher/quotes' + queryString;
  }
  if (groups.includes(roleDealer)) {
    url = '/dealer/quotes' + queryString;
  }

  return useQuery({
    queryKey: url,
    queryFn: () => getDataFromApi(url),
    enabled: url !== '',
  });
}

export function useSaveUserQuotation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      const url = dto.hasQuotation ? `/polisher/quotes/${dto.qid}` : '/polisher/quotes';
      const method = dto.hasQuotation ? 'PATCH' : 'POST';
      return pushDataToApi(
        url,
        defaultPostHeaders,
        JSON.stringify(dto),
        method
      );
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: '/polisher/quotes' });
    }
  });
}

export function useJudgeUserQuotation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      const url = `/dealer/quotes/${dto.quote.id}/${dto.status}`;
      const requestBody = {
        judgeComments: dto.comment || '',
      };

      return pushDataToApi(
        url,
        defaultPostHeaders,
        JSON.stringify(requestBody)
      );
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: '/dealer/quotes' });
    }
  });
}


export async function createShowroomQuote(orderType, carBrand, polisherId) {
  return pushDataToApi('/dealer/showroom', defaultPostHeaders, JSON.stringify({
    orderType,
    carBrand,
    polisherId
  }));
}
