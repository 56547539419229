import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import CarCardItem from './CarCardItem';
import { BroomIcon } from '../../assets/icon/broom';
import { PencilIcon } from '../../assets/icon/pencil';
import {
  buttonStyling,
  gradientButtonStyling,
  adjustButton,
  blockButton,
} from '../../assets/styling/buttons';
import LabelStatus from '../LabelStatus';

function CarCard({ items, onPriceClick, onJudgeClick, onPolishedClick }) {
  return (
    <Grid container spacing={2} sx={{ mb: 6 }}>
      {items.map((item) => {
        const showJudgeButton = item.judgedAt === null && item.showQuoteButton === false;
        const showQuoteButton = item.approved === false && onPriceClick;

        return (
          <Grid item key={item.id} xs={12} sm={6} lg={3}>
            <Card
              sx={{
                p: 2,
                mb: 2,
                height: '95%'
              }}
            >
              <CardContent sx={{ p: 0 }}>
                {item.showJudgedLabel && item.judgedAt && (
                  <LabelStatus
                    ok={item.approved}
                    trueLabel="Akkoord"
                    falseLabel="Afgekeurd"
                  />
                )}
                {item.showPolishedLabel && (
                  <LabelStatus
                    ok={item.polished}
                    trueLabel="Gepoetst"
                    falseLabel="Niet gepoetst"
                  />
                )}
                {item.showInvoicedLabel && (
                  <LabelStatus
                    ok={item.invoiced}
                    trueLabel="Gefactureerd"
                    falseLabel="Niet gefactureerd"
                  />
                )}
                {item.showShowroomLabel && (
                  <LabelStatus
                    ok={true}
                    trueLabel="Showroom naloop"
                    falseLabel=""
                  />
                )}
                <h2
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                  }}
                >
                  {item.brand + ' ' + item.model}
                </h2>
                <Grid container sx={{ maxHeight: 500, height: 500 }}>
                  <CarCardItem
                    title="Kenteken"
                    value={
                      item.licenseplate ? item.licenseplate : 'Niet Bekend'
                    }
                  />
                  <CarCardItem
                    title="Chassisnr"
                    value={
                      item.chassisnumber ? item.chassisnumber : ' Niet Bekend'
                    }
                  />
                  <CarCardItem
                    title="Ordersoort"
                    value={item.ordertype ? item.ordertype : ' - '}
                  />
                  <CarCardItem
                    title="Napoetsen"
                    value={item.repolish ? 'Ja' : 'Nee'}
                  />
                  <CarCardItem
                    title="Info"
                    value={item.info ? item.info : 'Niet Bekend'}
                  />
                  <CarCardItem
                    title="Aflevering"
                    value={
                      item.deliveryAt
                        ? new Date(item.deliveryAt).toLocaleDateString('nl-NL')
                        : 'Niet Bekend'
                    }
                  />
                  <CarCardItem
                    title="Technisch klaar"
                    value={item.techready ? 'Ja' : 'Nee'}
                  />
                  <CarCardItem
                    title="Vestiging"
                    value={
                      item.establishment ? item.establishment : 'Niet Bekend'
                    }
                  />
                  <CarCardItem
                    title="Verkoper"
                    value={item.salesperson ? item.salesperson : 'Niet Bekend'}
                  />
                  <CarCardItem
                    title="VSB"
                    value={item.vsb ? item.vsb : 'Niet Bekend'}
                  />
                  <CarCardItem
                    title="Prijsopgave"
                    value={
                      item.quote
                        ? '€ ' + String(item.quote).replace('.', ',')
                        : ''
                    }
                  >
                    { showQuoteButton &&
                      <Button
                        sx={adjustButton}
                        onClick={() => onPriceClick(item)}
                      >
                        aanpassen
                        <PencilIcon />
                      </Button>
                    }
                  </CarCardItem>
                  <CarCardItem
                    title="Opm. poetser"
                    value={item.comments ? item.comments : ''}
                  />
                  <CarCardItem
                    title="Opm. dealer"
                    value={item.judgeComments ? item.judgeComments : ''}
                  />
                </Grid>
              </CardContent>
              {item.showPolishedButton && (
                <CardActions sx={{ pl: 0 }}>
                  <Button
                    onClick={() => onPolishedClick(item)}
                    sx={[buttonStyling, gradientButtonStyling, blockButton]}
                  >
                    {item.polished
                      ? 'Markeer als niet gepoetst'
                      : 'Markeer als gepoetst'}
                    <BroomIcon />
                  </Button>
                </CardActions>
              )}
              {showJudgeButton && (
                <Button
                  sx={{ float: 'right', marginTop: '10px' }}
                  variant="contained"
                  startIcon={<GavelIcon />}
                  onClick={() => onJudgeClick(item)}
                >
                  Keuren
                </Button>
              )}
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CarCard;
