import TasksFilter from '../components/TasksFilter';
import { useGetUserTasks, usePatchTask } from '../services/taskService';
import { useUser } from '../contexts/UserProvider';
import CarCard from '../components/Card/CarCard';
import ErrorCard from '../components/General/ErrorCard';
import { useState } from 'react';
import Loading from '../components/General/Loading';

export default function Tasks() {
  const { groups } = useUser();
  const [filters, setFilters] = useState({
    invoiced: false,
  });
  const { isLoading, isError, error, data } = useGetUserTasks(groups, filters);
  const mutation = usePatchTask();

  if (isError) {
    return <ErrorCard errorMessage={error.message} />;
  }

  const tasksForCarCard = (tasks) => {
    return tasks.map((task) => ({
        ...task.quotation,
      id: task.id,
      polished: task.polished,
      showShowroomLabel: task.quotation.showroomQuote,
      showPolishedButton: true,
      showPolishedLabel: true,
    }));
  };

  const onFilter = (chassisnumber, taskStatus, dateRange) => {
    const fr = {
      invoiced: false,
    };
    if (chassisnumber) {
      fr.chassisnumber = chassisnumber;
    }
    if (taskStatus) {
      fr.polished = taskStatus === 'Gepoetst';
    }
    if (dateRange && dateRange[0]) {
      fr.fromDate = dateRange[0];
    }
    if (dateRange && dateRange[1]) {
      fr.toDate = dateRange[1];
    }
    setFilters(fr);
  };

  const onPolishedClick = async (item) => {
    await mutation.mutate({
      id: item.id,
      polished: !item.polished
    });
  };

  return (
    <>
      <h1>Poetsopdrachten</h1>

      <TasksFilter onFilter={onFilter} statusOptions={['', 'Gepoetst', 'Niet gepoetst']} />
      { isLoading && <Loading /> }
      { !isLoading && data && data.length === 0 && <h3>Geen opdrachten gevonden</h3> }
      { data && <CarCard items={tasksForCarCard(data)} onPolishedClick={onPolishedClick} /> }
    </>
  );
}
