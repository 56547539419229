import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import { gradientButtonStyling } from '../assets/styling/buttons';
import FilterSelect from './FilterSelect';
import { getPolishersWithSyntecDetails } from '../services/userService';
import { createShowroomQuote } from '../services/quotationService';

const severityError = 'error';
const severitySuccess = 'success';

function ShowroomQuoteForm () {
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getPolishersWithSyntecDetails();
      setUsers(response.results);
      setUserOptions(response.results.map((item) => `${item.firstname} ${item.lastname} <${item.email}>`));
    };

    fetchData().catch((e) => {
      setSaveMsg(e.message);
      setSaveSeverity(severityError);
    });
  }, [])

  const [userSelect, setUserSelect] = useState('');
  const [userSelectId, setUserSelectId] = useState('');
  const [orderTypeSelect, setOrderTypeSelect] = useState('');
  const [brandSelect, setBrandSelect] = useState('');
  const [saveMsg, setSaveMsg] = useState(null);
  const [saveSeverity, setSaveSeverity] = useState('');

  const onSave = async () => {
    if (userSelectId && orderTypeSelect && brandSelect) {
      const res = await createShowroomQuote(orderTypeSelect, brandSelect, userSelectId);
      if (res.status === 201) {
        setSaveSeverity(severitySuccess);
        setSaveMsg('Opgeslagen');
      } else {
        setSaveSeverity(severityError);
        setSaveMsg(res.statusText);
      }
      return;
    }

    setSaveSeverity(severityError);
    setSaveMsg('Alle velden zijn verplicht');
  };

  const setUserSelectWrapper = useCallback((selected) => {
    setUserSelect(selected);
    const user = users.filter((item) => `${item.firstname} ${item.lastname} <${item.email}>` === selected).pop();
    if (user) {
      setUserSelectId(user.id);
    }
  }, [users]);

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <h2>Nieuwe opdracht</h2>

        <Grid item xs={12} md>
          <FilterSelect
            name='orderType'
            label='Ordertype'
            options={['', 'Gebruikt', 'Nieuw']}
            filterValue={orderTypeSelect}
            setFilterValue={setOrderTypeSelect}
          />
        </Grid>

        <Grid item xs={12} md>
          <FilterSelect
            name='brand'
            label='Merk'
            options={['', 'Volkswagen', 'Audi', 'Seat', 'Skoda', 'Volkswagen Bedrijfswagens']}
            filterValue={brandSelect}
            setFilterValue={setBrandSelect}
          />
        </Grid>

        <Grid item xs={12} md>
          <FilterSelect
            name='user'
            label='Poetser'
            options={userOptions}
            filterValue={userSelect}
            setFilterValue={setUserSelectWrapper}
          />
        </Grid>

        <Button
          sx={[gradientButtonStyling, { mt: 2, p: 1.5 }]}
          variant="contained"
          onClick={onSave}
        >
          Aanvragen
        </Button>
        {saveMsg && (
          <Alert sx={{ mt: 2 }} severity={saveSeverity}>
            {saveMsg}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

export default ShowroomQuoteForm;
