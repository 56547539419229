import { Link } from 'react-router-dom';
import { Container } from '@mui/material';

export default function Info() {
  return (
    <Container maxWidth='md' className='content' sx={{ mt: 8, mb: 5 }}>
      <h1>Info</h1>

      <p>
        <Link to='/privacy-statement'>Privacy Statement</Link>
      </p>
      <p>
        <Link to='/cookie-statement'>Cookie Statement</Link>
      </p>
    </Container>
  );
}
