export const buttonStyling = {
  fontWeight: 'bold',
  padding: '8px !important',
  marginRight: '4px !important',
  borderRadius: '3px !important',
  textTransform: 'capitalize',
};

export const gradientButtonStyling = {
  background: 'linear-gradient(to bottom, #f34b15, #f75c0e 32%, #ff8000)',
  color: '#ffffff !important',
  fontSize: '14px !important',
  lineHeight: 1,
  '&:hover': {
    background: 'linear-gradient(to bottom, #ad4221, #f75c0e 62%, #ff8000)',
  },
};

export const adjustButton = {
  border: '1px solid #f34b15',
  fontSize: '10px',
  padding: '0px 4px',
  marginLeft: '4px',
  textTransform: 'capitalize',
};

export const notApprovedButton = {
  background: '#676d7d',
  color: '#ffffff !important',
  fontSize: '14px !important',
  lineHeight: 1,
};

export const blockButton = {
  display: 'block',
  width: '100%',
};

export const simpleLink = {
  textTransform: 'none',
  padding: '5px 0',
  color: 'green',
}
