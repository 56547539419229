import SyntecForm from '../components/User/SyntecForm';
import { Card, CardContent, Grid } from '@mui/material';
import { useUser } from '../contexts/UserProvider';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

function User() {
  const { user } = useUser();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const handleOnClick = async () => {
    const basename = window.location.origin + history.createHref({ pathname: '/' });
    await oktaAuth.signOut({ postLogoutRedirectUri: basename });
  };

  if (!user) {
    return '';
  }

  return (
    <>
      <h1>Gebruikersgegevens</h1>
      <Grid item xs={12} sm={6} lg={3}>
        <Card
          sx={{
            p: 2,
            mb: 2,
          }}
        >
          <CardContent sx={{float: 'right'}}>
            <Button variant="contained" onClick={handleOnClick}>Uitloggen</Button>
          </CardContent>
          <CardContent>
            Naam: {user.name} <br />
            Email: {user.preferred_username}
          </CardContent>
          <CardContent>
            <SyntecForm />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default User;
