import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { oktaAuth } from './oktaAuth';

export default function useAuthUser() {
  const { authState } = useOktaAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.token
        .getUserInfo()
        .then((info) => {
          const user = {
            groups: authState.accessToken.claims.groups,
            ...info
          };
          setUser(user);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState]);

  return [authState, user];
}
