import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  outlinedInputClasses,
} from '@mui/material';
import { theme } from '../setting';

const StyledForm = styled('form')(`
 display: flex;
 flex-wrap: wrap;
`);

const StyledFormControl = styled(FormControl)(() => ({
  minWidth: 160,
}));

const StyledSelect = styled(Select)(`
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: ${theme.palette.orange.main};
  }
`);

function FilterSelect({ name, label, options, filterValue, setFilterValue }) {
  const onChange = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <StyledForm autoComplete='off'>
      <StyledFormControl variant='outlined'>
        <InputLabel id={name}>{label}</InputLabel>
        <StyledSelect
          label='Package Type'
          labelId={name}
          value={filterValue}
          onChange={onChange}
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option}>
                {option === '' ? '-' : option}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </StyledFormControl>
    </StyledForm>
  );
}

export default FilterSelect;
