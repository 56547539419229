import { OKTA_CLIENT_ID, OKTA_DOMAIN } from '../Constants';
import { OktaAuth } from '@okta/okta-auth-js';

export const oktaAuth = new OktaAuth({
  issuer: `${OKTA_DOMAIN}`,
  clientId: `${OKTA_CLIENT_ID}`,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'groups', 'roles', 'offline_access'],
  responseType: 'token',
});

export const rolePolisher = 'Poetstool poetsers';
export const roleDealer = 'Poetstool dealers';
export const roleAdmin = 'Poetstool admins';
