import { Link } from 'react-router-dom';
import { Container } from '@mui/material';

export default function PrivacyStatement() {
  return (
    <Container maxWidth='md' className='content' sx={{ mt: 8, mb: 5 }}>
      <h1>Privacy statement</h1>

      <h3>Privacyverklaring Huiskes-Kokkeler</h3>
      <p>
        Huiskes-Kokkeler vindt de bescherming van persoonsgegevens van
        essentieel belang. Wij verwerken jouw gegevens dan ook met de grootst
        mogelijke zorgvuldigheid. Met deze privacyverklaring informeren wij jou
        over hoe wij omgaan met jouw persoonsgegevens.
      </p>

      <h3>1. Wie is verantwoordelijk voor de verwerking van jouw gegevens?</h3>
      <p>
        In deze privacyverklaring beschrijft Huiskes-Kokkeler
        Automobielbedrijven B.V. (hierna: “<strong>Huiskes-Kokkeler </strong>”
        of “<strong>wij</strong>”) hoe zij als verwerkingsverantwoordelijke met
        jouw persoonsgegevens omgaat. Wij zijn verbonden aan Pon Holdings B.V.
        ("<strong>Pon</strong>") en maken onderdeel uit van de Dealer Groep Pon.
      </p>

      <h3>2. Wat is een persoonsgegeven?</h3>
      <p>
        Informatie waarmee wij een natuurlijk persoon kunnen identificeren, is
        een persoonsgegeven, bijvoorbeeld jouw naam of e-mailadres. Of deze
        informatie als een persoonsgegeven kan worden gekwalificeerd, hangt
        bijvoorbeeld af van of Huiskes-Kokkeler beschikt over wettige middelen
        waarvan redelijkerwijs mag worden aangenomen dat wij deze inzetten om
        een natuurlijk persoon te identificeren. Zo beschouwen wij jouw kenteken
        en chassisnummer bijvoorbeeld ook als een persoonsgegeven.
      </p>

      <h3>3. Persoonsgegevens die wij verwerken</h3>
      <p>
        Wij verwerken persoonsgegevens over jou omdat jij gegevens zelf aan ons
        verstrekt bij het aangaan van een overeenkomst met ons of een van onze
        dealers of servicepartners. Wanneer je gebruik maakt van onze diensten
        kunnen wij bepaalde gegevens over jou vastleggen, bijvoorbeeld jouw
        interesses en communicatievoorkeuren. Hieronder volgt meer toelichting.
      </p>

      <strong>Gegevens door jou aan ons verstrekt</strong>
      <p>
        Als je met Huiskes-Kokkeler een overeenkomst aangaat, hebben wij de
        volgende persoonsgegevens van jou nodig:
      </p>
      <ul>
        <li>naam en adres</li>
        <li>contactgegevens (telefoonnummers, e-mailadressen)</li>
        <li>geboortedatum</li>
        <li>geslacht</li>
        <li>klantnummer(s)</li>
        <li>rijbewijsnummer</li>
        <li>jouw voorkeur voor dealer of servicepartner</li>
        <li>
          financiële gegevens (bankrekeninginformatie, offertes, facturen).
        </li>
      </ul>

      <p>
        Daarnaast verwerkt Huiskes-Kokkeler in het kader van de uitvoering een
        overeenkomst met jou of het verlenen van een dienst aan jou de volgende
        gegevens die als persoonsgegevens kunnen kwalificeren:
      </p>
      <ul>
        <li>kenteken van jouw voertuig;</li>
        <li>chassisnummer en model van jouw voertuig;</li>
        <li>technische gegevens over jouw voertuig;</li>
        <li>onderhoudshistorie van jouw voertuig;</li>
        <li>eigendomshistorie van jouw voertuig (tenaamstellingsgegevens);</li>
        <li>andere gegevens relevant voor de betreffende overeenkomst.</li>
      </ul>

      <strong>
        Gegevens over jouw gebruik van onze diensten en/of producten
      </strong>
      <p>
        Als je gebruik maakt van diensten van Huiskes-Kokkeler of anderszins
        contact hebt met ons, verwerken wij in het kader van onze
        dienstverlening – afhankelijk van het precieze gebruik dat jij ervan
        maakt – de volgende gegevens die als persoonsgegevens (kunnen) worden
        gezien:
      </p>
      <ul>
        <li>
          Gegevens die jij verstrekt bij het gebruik van onze website(s) of
          online klantenportaal, zoals naam, adres, contactgegevens,
          communicatievoorkeuren en mogelijk inloggegevens. Hieronder vallen ook
          de gegevens die jij aan ons doorgeeft en wij mogelijk verstrekken aan
          derden voor bijvoorbeeld het aanvragen van een offerte, het maken en
          bewaren van een online configuratie, het plannen van een proefrit of
          een werkplaatsafspraak. Voor beheerdoeleinden kunnen wij met behulp
          van logbestanden bijhouden wanneer je hebt ingelogd en bijvoorbeeld
          wat je hebt aangepast.
        </li>
        <li>
          Bij het plannen van een proefrit hebben wij jouw rijbewijsnummer en
          geboortedatum nodig zodat wij kunnen borgen dat je bevoegd bent om het
          voertuig te besturen. Om je identiteit te verifiëren en de geldigheid
          van jouw rijbevoegdheid vast te stellen wordt door ons mogelijk een
          validatie gedaan bij het RDC (onderdeel van BOVAG). Deze gegevens
          gebruiken wij ook wanneer u een voertuig bij ons aanschaft en wij deze
          tenaamstellen via het RDW.
        </li>
        <li>
          Een voertuig kan aan jouw klantprofiel gekoppeld worden. Deze
          koppeling kan op meerdere manieren tot stand komen, bijvoorbeeld omdat
          jij dit zelf aan ons doorgeeft of wanneer je bij ons voertuig
          aanschaft. De ontkoppeling van het voertuig van jouw klantprofiel
          vindt automatisch plaats wanneer wij een tenaamstellingswisseling (1b)
          doorkrijgen van het RDW via onze importeur. Ook kun je het voertuig
          laten ontkoppelen door dit aan te geven via de klantenservice.
        </li>
        <li>
          Gegevens die betrekking hebben op jouw randapparatuur, zoals een
          MACadres, IP-adres of ander nummer.
        </li>
        <li>
          Gegevens die wij verbinden aan bepaalde patronen, zoals een status in
          het profiel dat wij over jou opstellen aan de hand van jouw
          consumptiegedrag, bijvoorbeeld de frequentie waarmee je een aankoop
          doet of dienst afneemt.
        </li>
        <li>
          Surfgedrag, vastgesteld aan de hand van onze eigen observaties
          bijvoorbeeld met behulp van cookies of soortgelijke technieken (zie
          ook artikel 9 van deze privacyverklaring).
        </li>
        <li>
          Klanttevredenheidsgegevens en gegevens over jouw gebruik van onze
          klantenservice. Hieronder vallen ingezonden brieven en e-mails, online
          chatsessies maar ook telefoongesprekken die je met ons voert en die
          kunnen worden opgenomen voor trainingsdoeleinden van onze medewerkers.
          Online chatsessies kunnen mogelijk aan jouw klantprofiel gekoppeld
          worden indien je hiervoor toestemming geeft.
        </li>
      </ul>

      <strong>
        Gegevens die wij ontvangen van bij ons aangesloten importeur,
        servicepartners of andere businesspartners
      </strong>
      <p>
        In sommige gevallen ontvangen wij jouw persoonsgegevens van derden om
        onze of een gezamenlijke dienstverlening mogelijk te kunnen maken. De
        partijen waarvan wij gegevens ontvangen zijn:
      </p>

      <ul>
        <li>
          Importeur van onze merken: jouw naam, adres, klantnummer,
          communicatievoorkeuren, voertuig- en gebruiksgegevens zoals: afgenomen
          (onderhouds)diensten, kenteken, chassisnummer, tenaamstelling voertuig
          en onderhoudsgegevens. Wij gebruiken deze gegevens voor de volgende
          doeleinden:
          <ul>
            <li>
              om overeengekomen (onderhouds)diensten en producten te kunnen
              leveren;
            </li>
            <li>
              indien nodig te herroepen en tegemoet te komen aan jouw verzoeken;
            </li>
            <li>
              om onze dienstverlening te analyseren en te optimaliseren, ook
              door jouw gegevens te combineren met andere relevante gegevens;
            </li>
            <li>voor (direct) marketing- en verkoopdoeleinden.</li>
          </ul>
        </li>
        <li>
          Dienst Wegverkeer (RDW): naam, adres, kenteken, chassisnummer,
          tenaamstelling wijziging (1b). Jouw naam en adres verkrijgen wij
          alleen wanneer sprake is van een herroeping van een voertuig op basis
          van een wettelijke verplichting. Veelal gaat het dan om verplichte
          reparaties/updates die noodzakelijk zijn om verkeersveiligheid te
          borgen.
        </li>
        <li>
          EDM B.V.: naam, straat, postcode, woonplaats en geboortedatum. Deze
          gegevens worden gebruikt voor datakwaliteitsdoeleinden om jouw
          gegevens zo up-to-date mogelijk te houden en om jouw gegevens actief
          te verwijderen uit onze systemen om bijvoorbeeld ongewenste
          communicatie te voorkomen.
        </li>
        <li>
          Smart Profile B.V. en Graydon Nederland B.V.: naam en functie van
          contactpersoon en naam organisatie. Deze gegevens worden gebruikt voor
          datakwaliteitsdoeleinden om jouw gegevens zo up-to-date mogelijk te
          houden. Ook Kunnen deze gegevens gebruikt worden voor marketing- en
          verkoopdoeleinden.
        </li>
      </ul>

      <h3>4. Geen verwerking van gegevens van personen jonger dan 16 jaar</h3>
      <p>
        Wij hebben niet de intentie gegevens te verzamelen van websitebezoekers
        die jonger zijn dan 16 jaar. Wij raden ouders aan betrokken te zijn bij
        de online activiteiten van hun kinderen om zo te voorkomen dat
        Huiskes-Kokkeler hun persoonsgegevens verwerkt.
      </p>

      <h3>5. Verwerking: grondslagen en gerechtvaardigde belangen</h3>
      <p>
        De verwerking van persoonsgegevens door Huiskes-Kokkeler vindt plaats op
        basis van de volgende grondslagen uit artikel 6 van de Algemene
        Verordening Gegevensbescherming (AVG):
      </p>
      <ol>
        <li>Toestemming</li>
        <li>Uitvoering van een overeenkomst met jou</li>
        <li>Wettelijke verplichting</li>
        <li>
          Gerechtvaardigd belang van Huiskes-Kokkeler of een derde
          Gerechtvaardigde belangen zijn onder andere: marketing, reclame,
          (IT-)beveiliging, preventie van fraude of andere criminele
          activiteiten, IT-beheer, onderzoek en analyse naar eigen producten of
          diensten, bedrijfshuishouding, juridische zaken en intern beheer.
        </li>
      </ol>

      <h3>6. Doeleinden voor het verwerken van persoonsgegevens</h3>
      <p>
        Huiskes-Kokkeler gebruikt jouw persoonsgegevens voor de hieronder
        genoemde doeleinden. Het cijfer achter ieder doel correspondeert met de
        grondslag genoemd in artikel 5 van deze privacyverklaring:
      </p>
      <ul>
        <li>
          Om jou onze diensten en producten te kunnen leveren, inclusief het
          afhandelen van bestellingen, het verwerken in financiële
          administraties en logistieke afhandeling [grondslag: 2].
        </li>
        <li>
          Het bieden van klantenservice inclusief service rondom het kopen van
          diensten en producten, het afhandelen van klachten en verzoeken, het
          vervullen van garantieverplichtingen of productherroepingen voor onze
          eigen dienstverlening [grondslag: 2 of 4].
        </li>
        <li>
          Het opbouwen en onderhouden van de klantrelatie met jou inclusief het
          onderhouden van een directe relatie tussen de fabrikant, importeur en
          aanverwante financiële dienstverleners, onder meer voor commerciële
          doeleinden bijvoorbeeld om proactief contact met jou op te nemen in
          het kader van voertuiggebreken of -storingen of om serviceafspraken te
          plannen inclusief het mogelijk maken van mobiliteitsservices
          [grondslag: 2 of 4].
        </li>
        <li>
          Om jou te informeren over onze producten en diensten kunnen wij jou
          via verschillende media benaderen met aanbiedingen, een
          loyaliteitsprogramma, prijsvraag, nieuwsbrief of andere commerciële
          informatie. Je hebt daarbij altijd de mogelijkheid om jezelf hiervoor
          af te melden door bezwaar te maken of jouw toestemming in te trekken.
          Bij alle communicatie is aangegeven op welke wijze je dit kunt doen.
          Wij proberen onze communicatie op jouw persoonlijke voorkeur af te
          stemmen. Het mogelijk koppelen en analyseren van cookies van onze
          websites aan bij ons bekende klantgegevens valt ook hieronder en doen
          wij alleen op basis van jouw toestemming. [grondslag: 1, 2 of 4].
        </li>
        <li>
          Om onze bedrijfsvoering, merken, diensten en producten te verbeteren
          verwerken wij mogelijk jouw gegevens, bijvoorbeeld voor
          marktonderzoek, het meten van klanttevredenheid, het opmaken van 5
          managementinformatie en het verbeteren van datakwaliteit met behulp
          van adresvalidatie-technieken. Hierbij gebruiken wij zo min mogelijk
          jouw persoonsgegevens en werken zoveel mogelijk met niet herleidbare
          gegevens. [grondslag: 1 of 4].
        </li>
        <li>
          Het voldoen aan wettelijke verplichtingen, beslechting van geschillen
          en handhaving van onze rechten en overeenkomsten [grondslag: 3 of 4].
        </li>
        <li>
          Het kunnen aanbieden van een proefrit of anderszins tijdelijk
          beschikbaar stellen van een voertuig. Hierbij kunnen wij jou vragen je
          te identificeren met een geldig legitimatiebewijs en/of wanneer
          noodzakelijk maken wij een afgeschermd kopie van jouw rijbewijs of
          identiteitskaart/paspoort voor de duur van het gebruik van het
          voertuig. Dit doen wij om jouw identiteit vast te stellen en om het
          mogelijk te maken om aangifte te doen wanneer het vermoeden bestaat
          van diefstal of er sprake is van aantoonbare schade of wangedrag.
          [grondslag: 2].
        </li>
      </ul>

      <h3>7. Delen met derden</h3>
      <p>
        Huiskes-Kokkeler deelt jouw persoonsgegevens met derden in onder andere
        de volgende gevallen en met bijbehorende redenen. Het cijfer achter
        ieder doel correspondeert met de grondslag genoemd in artikel 5 van deze
        privacyverklaring. Wij kunnen jouw gegevens verstrekken aan:
      </p>
      <ul>
        <li>
          Gelieerde financiële dienstverleners met als doel aansluitende
          financiële diensten aan te bieden [grondslag: 2].
        </li>
        <li>
          De importeur van het merk van jouw voertuig. Hierbij worden jouw
          gegevens verstrekt die bijvoorbeeld nodig zijn voor de levering van
          een (gezamenlijke) dienst of product op basis van een overeenkomst met
          jou. [grondslag: 2].
        </li>
        <li>
          Bij het plannen van een proefrit kunnen wij jou vragen om ons te
          voorzien van jouw rijbewijsnummer en geboortedatum. Dit doen wij om te
          borgen dat je bevoegd bent om het voertuig te besturen. Om je
          identiteit te verifiëren en de geldigheid van jouw rijbevoegdheid vast
          te stellen, wordt door ons mogelijk een validatie gedaan bij het RDC
          (onderdeel van BOVAG). [grondslag: 2].
        </li>
        <li>
          Derden die voor Huiskes-Kokkeler advertenties verzorgen. Hierbij
          worden jouw contactgegevens (e-mail en/of telefoonnummer) in gehashte
          vorm (pseudoniem, niet direct naar jou herleidbaar) verwerkt en
          verstrekt aan deze partijen voor zover dit wettelijk is toegestaan.
          Deze verstrekking vindt alleen plaats om doelgericht te kunnen
          adverteren, bijvoorbeeld via sociale media platformen of zoekmachines.
          [grondslag: 4].
        </li>
        <li>
          Derden gelieerd aan Huiskes-Kokkeler kunnen wij cookies van onze
          websites verstrekken om de inhoud van communicatie zo goed mogelijk op
          jouw persoonlijke voorkeur af te stemmen op hun eigen websites
          [grondslag: 1].
        </li>
        <li>
          Derden als wij wettelijk verplicht zijn om persoonsgegevens aan deze
          derden te verstrekken [grondslag: 3].
        </li>
        <li>
          Derden als wij een vermoeden hebben van een schending van de rechten
          van derden of van strafbare feiten of misbruik. Wij kunnen in deze
          gevallen persoonsgegevens verstrekken aan derden die daarbij een
          gerechtvaardigd belang hebben of aan instanties die het algemeen
          belang dienen. Dit kunnen ook handhavende autoriteiten zijn, zoals het
          Openbaar Ministerie of toezichthouders [grondslag: 3 of 4].
        </li>
        <li>
          Andere bedrijfsonderdelen binnen Pon voor de uitvoering van een
          overeenkomst met jou of vanwege gerechtvaardigde bedrijfsbelangen,
          zoals het voeren van een centrale administratie of klantenservice en
          het analyseren van onze dienstverlening aan jou [grondslag: 2 of 4].
        </li>
        <li>
          Derden die Huiskes-Kokkeler assisteren in haar dienstverlening en geen
          verwerker zijn, denk hierbij aan accountants en (juridisch) adviseurs
          [grondslag: 4].
        </li>
        <li>
          Derden voor bedrijfseconomische doeleinden, denk hierbij aan de
          verkoop van bedrijfsactiviteiten of een reorganisatie [grondslag: 4].
        </li>
      </ul>
      <p>
        Huiskes-Kokkeler gebruikt diensten van derden die kunnen fungeren als
        'verwerkers', bijvoorbeeld hosting-dienstverleners,
        mobiliteitsdienstverleners en onderzoeksbureaus met als doel
        marktonderzoek of klanttevredenheidsonderzoek uit te voeren. Deze
        dienstverleners ontvangen persoonsgegevens uitsluitend om te verwerken
        in overeenstemming met de instructies en onder de controle van
        Huiskes-Kokkeler. Als persoonsgegevens naar een ontvanger in het
        buitenland worden verzonden, gebeurt dit doorgaans alleen naar een
        ontvanger in een land dat volgens de Europese Commissie een voor
        persoonsgegevens passend beschermingsniveau biedt. Als persoonsgegevens
        naar een ontvanger in een land worden verstuurd dat geen passend
        beschermingsniveau biedt, zorgt Huiskes-Kokkeler ervoor dat de wettelijk
        vereiste waarborgen worden genomen. Indien je nadere informatie wenst te
        ontvangen over de doorgifte van jouw persoonsgegevens naar landen buiten
        de Europese Economische Ruimte, kun je contact opnemen met de
        Functionaris Gegevensbescherming van Pon (zie artikel 12).
      </p>

      <h3>8. Hoe lang wij gegevens bewaren</h3>
      <p>
        Wij bewaren persoonsgegevens zo lang als dit nodig is, bijvoorbeeld zo
        lang als jij klant bent of interesse blijft tonen in de diensten van
        Huiskes-Kokkeler. Soms zijn wij ook wettelijk verplicht jouw gegevens te
        bewaren. Wij hanteren de volgende termijnen:
      </p>
      <ul>
        <li>
          Gegevens die wij verwerken voor marketing- en verkoopdoeleinden
          bewaren wij twee jaar nadat een transactie met ons is afgehandeld.
          Bijvoorbeeld een aankoop in de webshop of de beëindiging van een
          contractdienst zoals een verlengde verzekering. Ook de gegevens die
          wij verwerken voor het maken van een offerte of een afspraak voor een
          proefrit vallen hieronder. Aan jouw klantprofiel gekoppelde voertuigen
          van Huiskes-Kokkeler bewaren wij uiterlijk vijf jaar tot na
          ontkoppeling van dit voertuig. Gegevens met betrekking tot een bezoek
          in de werkplaats van een dealer bewaren we uiterlijk vier jaar.
        </li>
        <li>
          Gegevens die wij verwerken wanneer je contact hebt met onze
          klantenservice bewaren wij zolang jij klant bent of tot uiterlijk twee
          jaar tot 7 na het laatste contactmoment. Hier geldt een uitzondering
          voor telefoongesprekken die eventueel worden opgenomen voor
          trainingsdoeleinden; deze worden uiterlijk na een periode van 30 dagen
          verwijderd. Ook voor gegevens die te maken hebben met klachten kan een
          uitzondering gemaakt worden; deze worden maximaal vijf jaar bewaard na
          het afhandelen van de klacht.
        </li>
        <li>
          Gegevens over websitebezoeken die met behulp van cookies zijn
          verkregen, worden zo kort mogelijk bewaard met een maximale periode
          van twee jaar. Zie voor meer informatie ons cookie statement.
        </li>
        <li>
          Financiële/fiscale gegevens die nodig zijn voor het opmaken van
          bijvoorbeeld facturen, contracten of interne rapportages bewaren wij
          minimaal zeven jaar op basis van wettelijke verplichtingen.
        </li>
      </ul>

      <h3>9. In kaart brengen websitebezoek en e-mail leesgedrag</h3>
      <p>
        Wanneer je onze websites, diensten, applicaties, communicatiediensten
        (zoals email) en hulpmiddelen bezoekt of gebruikt, kunnen wij cookies,
        webbakens en andere soortgelijke technologieën voor het opslaan van
        informatie gebruiken, met
      </p>
      <p>
        als doel advertentie-uitingen te personaliseren en jou een betere,
        snellere en veiligere klantervaring te bieden.
      </p>
      <p>
        Wanneer je geabonneerd bent op een van onze nieuwsbrieven dan kunnen
        deze e-mails bijvoorbeeld voorzien zijn van webbakens, ook wel pixels
        genoemd. Met behulp van deze pixels kunnen wij bijvoorbeeld meten of en
        wanneer je onze email hebt gelezen, met welke browser in welk
        voorkeurstaal dit is gebeurd, kunnen wij (delen van) het IP-adres van
        jouw apparaat zien en is voor ons inzichtelijk op welke links je
        eventueel geklikt hebt. Wanneer je wel onze nieuwsbrief wenst te
        ontvangen maar niet wenst dat deze analyses door ons worden uitgevoerd,
        dan kun je hierop bezwaar maken via onze klantenservice (“recht om
        bezwaar te
      </p>
      <p>
        maken tegen direct marketing”). Zie voor meer informatie over jouw
        rechten artikel 10 van deze privacyverklaring.
      </p>
      <p>
        Als je geen cookies of verstrekking daarvan aan derden wenst, kun je dit
        weigeren door op onze websites de cookie instellingen te wijzigen. Voor
        meer details over ons gebruik van cookies en device fingerprinting
        technieken verwijzen wij naar ons{' '}
        <Link title='Cookie Statement' to='/cookie-statement'>
          cookie statement
        </Link>
        .
      </p>

      <h3>10. Jouw rechten, waaronder het recht om bezwaar te maken</h3>
      <p>
        Je hebt het recht om te weten welke persoonsgegevens wij van jou hebben
        vastgelegd en aan wie wij jouw persoonsgegevens hebben verstrekt. Naast
        het recht op inzage heb je met betrekking tot onze verwerking van jouw
        persoonsgegevens de volgende rechten:
      </p>
      <ul>
        <li>
          het recht om jouw toestemming in te trekken, voor zover onze
          verwerking van jouw persoonsgegevens daarop is gebaseerd;
        </li>
        <li>
          het recht om een klacht in te dienen bij de Autoriteit
          Persoonsgegevens;
        </li>
        <li>
          het recht om jouw persoonsgegevens te (laten) rectificeren/corrigeren;
        </li>
        <li>het recht op verwijdering van jouw persoonsgegevens;</li>
        <li>het recht op beperking van verwerking;</li>
        <li>het recht op dataportabiliteit;</li>
        <li>het recht om bezwaar te maken tegen direct marketing;</li>
        <li>
          het recht om bezwaar te maken tegen verwerkingen die plaatsvinden
          vanwege de gerechtvaardigde belangen van Huiskes-Kokkeler of een derde
          (afhankelijk van jouw persoonlijke omstandigheden).
        </li>
      </ul>
      <p>
        Voor het indienen van jouw verzoek om één van bovenstaande rechten uit
        te oefenen, kun je contact met ons opnemen via de klantenservice (zie
        artikel 12). Je kunt jouw rechten slechts uitoefenen voor zover de wet
        je deze rechten toekent. Huiskes-Kokkeler spant zich zo goed mogelijk in
        om binnen de wettelijke termijnen te reageren op jouw ingediende
        verzoek. Buitensporige verzoeken kunnen wij buiten behandeling laten. Om
        zeker van te zijn dat een verzoek door jou zelf is gedaan, kunnen wij
        vragen om een kopie van jouw identiteitsbewijs bij dit verzoek mee te
        sturen. Dit vragen wij alleen als wij dit noodzakelijk achten om jou te
        kunnen identificeren en verzoeken wij ook om op deze kopie de pasfoto,
        MRZ (machine readable zone; de strook met nummers onderaan het
        paspoort), paspoortnummer en Burgerservicenummer (BSN) onherkenbaar te
        maken. De kopie van jouw identiteitsbewijs vernietigen wij direct nadat
        wij jou hebben geïdentificeerd.
      </p>
      <p>
        Bij jouw verzoek tot verwijdering van gegevens wissen wij waar mogelijk
        jouw gegevens, bijvoorbeeld gegevens die wij hebben gebruikt voor:
      </p>
      <ul>
        <li>
          Het opbouwen en onderhouden van de klantrelatie met jou inclusief het
          onderhouden van een directe relatie tussen de fabrikant, importeur en
          aanverwante financiële dienstverleners, onder meer voor commerciële
          doeleinden.
        </li>
        <li>
          Om onze bedrijfsvoering, merken, diensten en producten te verbeteren
          verwerken wij mogelijk jouw gegevens. Denk daarbij aan het uitvoeren
          van marktonderzoek, het meten van klanttevredenheid, het opmaken van
          managementinformatie en het verbeteren van datakwaliteit met behulp
          van adresvalidatie-technieken.
        </li>
        <li>
          Om jou te informeren over onze producten en diensten kunnen wij jou
          via verschillende media benaderen met aanbiedingen, een
          loyaliteitsprogramma, prijsvraag, nieuwsbrief of andere commerciële
          informatie.
        </li>
      </ul>
      <p>
        Sommige persoonsgegevens wissen wij niet omdat deze noodzakelijk zijn
        voor de naleving van onze wettelijke verplichtingen. Dit betreft jouw
        gegevens die verwerkt zijn voor administratieve doeleinden waarop
        bijvoorbeeld fiscale wetgeving van toepassing is. Hierbij kun je denken
        aan de gegevens die nodig zijn voor het opmaken van contracten of
        facturen, dit dienen wij minimaal 7 jaar te bewaren.
      </p>

      <h3>11. Beveiliging en bescherming van gegevens</h3>
      <p>
        Huiskes-Kokkeler past passende beveiligingsmaatregelen toe om misbruik,
        verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde
        wijziging zoveel mogelijk tegen te gaan. Huiskes-Kokkeler heeft zowel
        technische als organisatorische maatregelen genomen om jouw
        persoonsgegevens te beveiligen. Deze beveiligingsmaatregelen worden
        periodiek herzien op basis van dreigingen en industriestandaards.
      </p>

      <h3>12. Contact</h3>
      <p>
        Voor vragen en/of opmerkingen met betrekking tot dit privacyverklaring
        kun je contact opnemen met:
      </p>
      <p>Huiskes-Kokkeler Automobielbedrijven B.V.</p>
      <p>
        T.a.v. Privacy Officer
        <br />
        Goudstraat 65
        <br />
        7554 NG Hengelo
        <br />
        074 - 204 42 89
      </p>
      <p>
        Mailadres: <a href='mailto:privacy@pon.nl'>privacy@pon.com</a>
      </p>
      <p>
        Wij beschikken over een Functionaris Gegevensbescherming die is
        aangemeld bij de toezichthouder Autoriteit Persoonsgegevens. Dit is
        iemand die binnen onze organisatie toezicht houdt op de toepassing en
        naleving van privacywetgeving zoals de Algemene Verordening
        Gegevensbescherming (AVG). Onze Functionaris Gegevensbescherming is
        bereikbaar per e-mail via{' '}
        <a href='mailto:privacy@pon.com'>privacy@pon.com</a> en per post via
        bovenstaand adres (t.a.v. 'Privacy Officer').
      </p>

      <h3>13. Wijzigingen</h3>
      <p>
        De manier waarop wij persoonsgegevens verwerken en de samenstelling of
        hoeveelheid van gegevens die wij verwerken, kan wijzigen. Daarom
        behouden wij ons het recht voor deze privacyverklaring aan te passen. Je
        zult indien noodzakelijk op de hoogte worden gebracht daarvan.
      </p>
      <p>Deze privacyverklaring is voor het laatst bijgewerkt op 11-04-2022.</p>
    </Container>
  );
}
