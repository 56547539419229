import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { defaultModalStyle } from '../assets/styling/modal';
import { Alert, CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export default function DownloadDialog({ filename, href, open, onClose, mutationStatus, mutationError }) {
  const anchorRef = useRef(null);
  const [clickCount, setItemClickCount] = useState(0);

  useEffect(() => {
    if (anchorRef.current && clickCount === 0) {
      anchorRef.current.click();
      setItemClickCount(1);
    }
  }, [clickCount, href, anchorRef]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
    >
      <Box sx={[defaultModalStyle, { maxWidth: { xs: 275, md: 720 } }]}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          { mutationStatus === 'loading' && <h3>Bezig met downloaden <CircularProgress sx={{margin: '-15px 24px'}} /> </h3> }
          { mutationStatus !== 'loading' && href && <a ref={anchorRef} href={href} download={filename}>Download {filename}</a> }
        </div>
        { mutationError && <Alert severity="error">{mutationError.message}</Alert>}
      </Box>
    </Modal>
  );
}

export async function createFilenameUrl(response, setFileUrl, setFilename) {
  const blob = await response.clone().blob();
  const regex = /filename="(factuur.*)"/gm;
  const content = response.headers.get('content-disposition');
  const match = regex.exec(content);
  const filename = match[1] || 'invoice.pdf';
  const href = window.URL.createObjectURL(blob);
  setFileUrl(href);
  setFilename(filename);
}
