import React, { createContext, useContext } from 'react';
import { useGetSettings } from '../services/adminService';

const SettingsContext = createContext();

/**
 * @param {JSX.element} props - children component
 * @return {JSX.element} ...
 */
export function SettingsProvider(props) {
  const { data } = useGetSettings();

  return (
    <SettingsContext.Provider value={{...data}}>
      {props.children}
    </SettingsContext.Provider>
  );
}

/**
 * @return {JSX.element} ...
 */
export function useSettings() {
  return useContext(SettingsContext);
}
