import { useMutation, useQuery, useQueryClient } from 'react-query';
import { defaultPostHeaders, getDataFromApi, pushDataToApi } from '../dao/api';

export function usePostGenerateInvoice() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (dto) => {
      return pushDataToApi(
        '/invoices/generate',
        defaultPostHeaders,
        JSON.stringify(dto)
      );
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: '/polisher/tasks?polished=true' });
    }
  });
}

export function useDownloadInvoice(id) {
  const url = `/invoices/${id}/download`;

  return useQuery({
    queryKey: url,
    queryFn: () => getDataFromApi(url),
    enabled: typeof id === 'number',
  });
}

export function useGetInvoice(filterParams) {
  let queryString = '';
  if (filterParams) {
    queryString = '?' + new URLSearchParams(filterParams).toString();
  }

  const url = `/invoices` + queryString;

  return useQuery({
    queryKey: url,
    queryFn: () => getDataFromApi(url),
  });
}
