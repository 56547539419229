import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { theme } from '../setting';
import { ReactComponent as CardView } from '../assets/icons/card-view.svg';
import { ReactComponent as ListView } from '../assets/icons/list-view.svg';

export default function ViewDisplaySwitch({ viewDisplay, setViewDisplay }) {
  return (
    <Grid container direction='row' justifyContent='flex-end' sx={{ mb: 1.5 }}>
      <Button
        sx={{
          all: 'unset',
          fill: theme.palette.gray.light,
          '&:hover svg, &:focus svg, &.active svg': {
            cursor: 'pointer',
            fill: theme.palette.gray.main,
          },
        }}
        className={`${viewDisplay === 'card' ? 'active' : ''}`}
        onClick={() => setViewDisplay('card')}
      >
        <CardView />
      </Button>
      <Button
        sx={{
          all: 'unset',
          pl: 1.5,
          fill: theme.palette.gray.light,
          '&:hover svg, &:focus svg, &.active svg': {
            cursor: 'pointer',
            fill: theme.palette.gray.main,
          },
        }}
        className={`${viewDisplay === 'list' ? 'active' : ''}`}
        onClick={() => setViewDisplay('list')}
      >
        <ListView />
      </Button>
    </Grid>
  );
}
