import React from 'react';
import { Box, Container } from '@mui/system';
import { Link, NavLink } from 'react-router-dom';
import { theme } from '../setting';
import LogoSVG from '../assets/logo-huiskes-kokkeler.svg';
import IconButton from '@mui/material/IconButton';
import { useUser } from '../contexts/UserProvider';
import { roleDealer, rolePolisher, roleAdmin } from '../auth/oktaAuth';

const polisherMenu = () => {
  return (
    <Box sx={{ display: { xs: 'none', md: 'inline-block' } }}>
      <NavLink to="/prijsopgaven">Prijsopgaven</NavLink>
      <NavLink to="/poetsopdrachten">Poetsopdrachten</NavLink>
      <NavLink to="/factureren">Te Factureren</NavLink>
    </Box>
  );
};

const dealerMenu = () => {
  return (
    <Box sx={{ display: { xs: 'none', md: 'inline-block' } }}>
      <NavLink to="/beoordelen">Beoordelen</NavLink>
      <NavLink to="/proforma">Proforma</NavLink>
      <NavLink to="/showroom-naloop">Showroom naloop</NavLink>
    </Box>
  );
};

const adminMenu = () => {
  return (
    <Box sx={{ display: { xs: 'none', md: 'inline-block' } }}>
      <NavLink to="/instellingen">Instellingen</NavLink>
    </Box>
  );
};

const Header = () => {
  const { user, groups } = useUser();

  return (
    <Box
      sx={{
        pt: 3,
        pb: 3,
        backgroundColor: theme.palette.white,
        width: '100vw',
        position: 'relative',
      }}
    >
      <Container
        component="nav"
        maxWidth="xl"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Link to="/">
          <Box component="img" src={LogoSVG} alt="Huiskes-Kokkeler" />
        </Link>

        {groups.includes(rolePolisher) && polisherMenu()}
        {groups.includes(roleDealer) && dealerMenu()}
        {groups.includes(roleAdmin) && adminMenu()}

        <Box sx={{ flexGrow:1, textAlign: 'right' }}>
          <IconButton
            sx={{
              mx: 1,
              '&:hover': {
                background: 'none',
              },
            }}
          >
            {user && user.name && (
              <Link
                className="user"
                to="/gebruiker"
                style={{ textDecoration: 'none' }}
              >
                {user.name}
              </Link>
            )}
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
