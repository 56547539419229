import {
  buttonStyling,
  gradientButtonStyling,
} from '../assets/styling/buttons';
import Typography from '@mui/material/Typography';
import { BroomIcon } from '../assets/icon/broom';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Alert, Button, CircularProgress } from '@mui/material';
import { theme } from '../setting';
import DefaultModal from '../components/DefaultModal';

export default function JudgeModal ({ quote, closeModal, judgeQuote, mutationError, mutationStatus }) {
  let quoteComment = '';

  if (quote) {
    quoteComment = quote.judgeComments;
  }

  const handleChangeComments = (e) => {
    quoteComment = e.target.value;
  }

  return (
    <DefaultModal
      open={quote !== null}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
    >
      <Typography id="modal-modal-description" sx={{ pt: 4, pb: 3 }}>
        Je staat op het punt om de prijsopgaaf te beoordelen. Zodra je
        goedkeuring geeft, wordt er een opdracht verstrekt en is het niet meer
        mogelijk om de prijsopgaaf alsnog af te wijzen. Indien je de
        prijsopgaaf afkeurt, moet je verplicht een opmerking meegeven, zodat
        de poetsers kunnen zien waarom de prijsopgaaf is afgewezen.
      </Typography>
      <div className="modalPrice">
        <p>Prijs: € {String(quote.quote).replace('.', ',')}</p>
      </div>
      <Typography id="modal-modal-remark" sx={{ pb: 1 }}>
        Opmerkingen:
      </Typography>
      <TextareaAutosize
        className="modal-modal-judge-comments"
        onChange={handleChangeComments}
        aria-label="minimum height"
        defaultValue={quoteComment}
        minRows={7}
        style={{ width: '95%' }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingTop: 24,
        }}
      >
        { mutationStatus !== 'loading'
          &&
          <>
            <Button
              onClick={() => judgeQuote(quote, 'reject', quoteComment)}
              sx={[
                buttonStyling,
                {
                  color: `${theme.palette.red.dark} !important`,
                  fontSize: '1rem !important',
                },
              ]}
            >
              Afkeuren
              <BroomIcon/>
            </Button>
            <Button
              onClick={() => judgeQuote(quote, 'approve', quoteComment)}
              sx={[buttonStyling, gradientButtonStyling]}
            >
              Goedkeuren
              <BroomIcon/>
            </Button>
          </>
        }
        { mutationStatus === 'loading' && <CircularProgress /> }

      </div>
      { mutationError && <Alert severity="error">{mutationError.message}</Alert> }
    </DefaultModal>
  );
}
